export default {
  'nav.account': 'Personal Center',
  'nav.language': 'Vereinfachtes Chinesisch',
  'signup.success': 'Erfolgreich senden',

  //Fehler
  'contact.empty': 'Kontaktinformationen dürfen nicht leer sein',
  'phone.country.empty': 'Die Telefonvorwahl darf nicht leer sein',
  'phone.empty': 'Telefon darf nicht leer sein',
  'email.valid': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',

  // Basis
  'base.success': 'Erfolg',
  'base.save': 'Speichern',
  'base.slide': 'Zum Bestätigen ziehen Sie bitte den Schieberegler',
  'base.send.code': 'Code senden',
  'base.send.time': '({time}) Code erneut senden',
  'base.send.success': 'Erfolgreich gesendet',
  'base.view.more': 'Mehr anzeigen',
  'Basis.Sprache': 'Sprache',
  'base.previous': 'Vorherige Seite',
  'base.next': 'Nächste Seite',
  'base.total.page': 'Gesamt {page} Seiten',
  'base.go.to.page': 'Gehe zu Seite',
  'base.go': 'Gehe zu',
  'base.followers': 'Follower',
  'base.follow': 'Folgen',
  'base.following': 'Folge',
  'base.visit.store': 'Shop durchsuchen',
  'base.contact': 'Kontaktieren Sie uns',
  'base.delivery': 'Geschätzte Lieferzeit: 1-7 Tage',
  'base.search': 'Suchen',
  'base.search.text': 'Schlüsselwörter für die Suche eingeben',
  'base.server': 'Online-Kundenservice',

  //Index
  'text.categories.title': 'Produktkategorie',
  'text.welcome': 'Willkommen zum Einkaufen!',
  'text.wishlist': 'Wunschliste',
  'text.account': 'Personal Center',
  'text.orders': 'Bestellungen',
  'text.messages': 'Nachricht',
  'text.suggest.title': 'Exklusives Angebot',
  'text.suggest.content': 'Nur für neue globale Premium-Mitglieder!',
  'text.super.deals': 'Top-Produkte zu unglaublichen Preisen.',

  //Anmeldung
  'signup.register': 'Anmeldung',
  'signup.signin': 'Anmelden',
  'signup.store.signin': 'Firmen-Login',
  'signup.sign.out': 'Abmelden',
  'signup.email': 'E-Mail-Adresse',
  'signup.password': 'Passwort',
  'signup.qr.password': 'Passwort bestätigen',
  'signup.confirm.password': 'Passwort bestätigen',
  'signup.forgot.password': 'Passwort vergessen',
  'registrierung.einladung': 'Einladungscode',
  'signup.char': 'Charakter',
  'signup.contains': 'Enthält Zahlen, Buchstaben oder Symbole',
  'signup.qr.contains': 'Die beiden Passwörter sind nicht konsistent',
  'signup.create.account': 'Konto erstellen',
  'signup.accept': 'Erstelle ein Konto, du stimmst zu',
  'signup.member.agreement': 'Mitgliedervereinbarung',
  'signup.and': 'und',
  'signup.privacy.policy': 'Datenschutzrichtlinie',
  'signup.email.code': 'E-Mail-Bestätigung',
  'signup.last.step': 'Letzter Schritt',
  'signup.send.email': 'Bitte geben Sie den 4-stelligen Code ein, der an {email} gesendet wurde',
  'signup.modify.email': 'E-Mail ändern',
  'signup.verify.email': 'Bestätigungs-E-Mail',
  'signup.have.store': 'Händlerkonto haben',
  'signup.goto.signin': 'Business-Login',
  'signup.no.store': 'Kein Händlerkonto',
  'signup.goto.store': 'Händler anmelden',
  'signup.next': 'Weiter',
  'signup.your.email': 'Ihre E-Mail',
  'signup.code.text': 'Bestätigungscode',
  'signup.submit.signup': 'Jetzt anmelden',
  'signup.smrz': 'Authentifizierung mit echtem Namen',
  'signup.derb': 'Zweiter Schritt',
  'signup.qsrxm': 'Bitte geben Sie den Namen des Dokuments ein',
  'signup.qsrhm': 'Bitte geben Sie die ID-Nummer ein',

  //vergessen
  'forgot.title': 'Passwort zurücksetzen',
  'forgot.btn.check': 'E-Mail bestätigen',
  'forgot.reset.now': 'Jetzt zurücksetzen',

  //speichern
  'store.info.open': '{y} Woche geöffnet',

  // Fusszeile
  'footer.great.value': 'Toller Wert',
  'footer.great.value.desc': 'Wir bieten konkurrenzfähige Preise für über 100 Millionen Artikel.',
  'footer.shopping': 'Globales Einkaufen',
  'footer.shopping.desc': 'Wir versenden in über 200 Länder und Regionen und unsere Website ist in 7 Sprachen verfügbar.',
  'footer.safe.payment': 'Sichere Zahlung',
  'footer.safe.payment.desc': 'Bezahlen Sie mit den beliebtesten und sichersten Zahlungsmethoden der Welt.',
  'footer.shop.with.confidence': 'Mit Vertrauen einkaufen',
  'footer.shop.with.confidence.desc': 'Unsere Käuferschutzrichtlinie deckt Ihren gesamten Kaufprozess ab.',
  'footer.help.center': 'Hilfezentrum',
  'footer.help.center.desc': 'Unterstützung rund um die Uhr, um ein reibungsloses Einkaufserlebnis zu schaffen.',
  'footer.terms.conditions': 'Allgemeine Geschäftsbedingungen',
  'footer.return.policy': 'Rückgaberecht',
  'footer.support.policy': 'Support-Richtlinie',
  'footer.privacy.policy': 'Datenschutzrichtlinie',
  'footer.be.seller': 'Verkäufer werden',
  'footer.apply.now': 'Jetzt bewerben',
  'footer.stay.connected': 'In Verbindung bleiben',

  'footer.about.us': 'Über uns',
  'footer.about.company': 'Firmenprofil',
  'footer.about.video': 'Videoeinführung',
  'footer.contact': 'Kontakt',

  'footer.my.account': 'Mein Konto',
  'footer.my.logout': 'Abmelden',
  'footer.my.order': 'Bestellhistorie',
  'footer.my.wish': 'Meine Wunschliste',
  'footer.my.join': 'Partnermitglied werden',
  'footer.email': 'E-Mail',
  'footer.gfemail': 'Offizielle E-Mail',
  'footer.fwemail': 'Service-E-Mail',
  'footer.address': 'Adresse',

  'apply.success': 'Bewerbung erfolgreich',
  'apply.success.desc': 'Erfolgreich beworben, zum Anmeldekonto gehen',

  // Kategorien
  'category.title': 'Verwandte Kategorie',
  'category.all': 'Alle Kategorien',

  //Detail
  'detail.store.home': 'Zuhause des Geschäfts',
  'detail.sale.items': 'Artikelliste',
  'detail.recommend': 'Empfohlen',
  'detail.orders': 'Verkäufe',
  'detail.quantity': 'Menge',
  'detail.pieces.available': 'Verfügbar',
  'detail.delivery': 'Lieferung',
  'detail.free.shipping': 'Kostenloser Versand',
  'detail.estimated.delivery': 'Voraussichtliche Lieferung',
  'detail.Tage': 'Tage',
  'detail.buy.now': 'Kaufen',
  'detail.add.to.cart': 'Zum Warenkorb hinzufügen',
  'detail.buyer.protection': 'Käuferschutz',
  'detail.money.guarantee': 'Geld-zurück-Garantie',
  'detail.rückerstattung.desc': 'Erhalten Sie eine volle Rückerstattung, wenn der Artikel nicht wie beschrieben ist oder nicht geliefert wurde',
  'detail.description': 'Produktbeschreibung',
  'detail.customer.reviews': 'Kundenrezensionen',
  'detail.spezifikationen': 'Produktspezifikationen',
  'detail.top.selling.products': 'Meistverkaufte Produkte',
  'detail.recommended.for.you': 'Für Sie empfohlen',
  'detail.verkauft': 'Verkäufe',
  'detail.receipt': 'Empfang bestätigen',
  'detail.receipt.title': 'Eingang der Bestellung bestätigen?',
  'detail.empfang.inhalt': 'Nach der Bestätigung ist der Transaktionsauftrag abgeschlossen',
  'detail.comment': 'Kommentar',
  'detail.refund.title': 'Antrag auf Rückerstattung bestätigen',
  'detail.refund.content': 'Nach der Bestätigung wird für die Bestellung eine Rückerstattung beantragt',
  'detail.sqtk': 'Antrag auf Rückerstattung',

  //bestätigen
  'confirm.shipping.address': 'Empfängeradresse',
  'Änderung bestätigen': 'Ändern',
  'confirm.payment.methods': 'Zahlungsmethoden',
  'confirm.summary': 'Abrechnung',
  'confirm.total.item.costs': 'Artikelsumme',
  'Bestätigung Gesamt': 'Gesamt',
  'Kasse bestätigen': 'Zur Kasse',
  'confirm.place.order': 'Jetzt bestellen',
  'confirm.pay.now': 'Jetzt bezahlen',
  'confirm.order.desc': "Nachdem ich auf 'Bestellung aufgeben' geklickt habe, bestätige ich, dass ich gelesen und bestätigt habe",
  'confirm.order.policy': 'Alle Bedingungen und Richtlinien',
  'bestätige Zahlung': 'Global Shopping gewährleistet Ihre Informations- und Zahlungssicherheit',

  // Adresse
  'address.title': 'Empfängeradresse',
  'address.default': 'Standard',
  'Adresse bearbeiten': 'Bearbeiten',
  'address.delete': 'Löschen',
  'address.new': 'Neue Adresse erstellen',
  'address.contact': 'Kontakt',
  'address.address': 'Adresse',
  'address.phone': 'Telefonnummer',
  'address.set.default': 'Standard festlegen',
  'address.confirm': 'Bestätigen',
  'Adresse.Abbrechen': 'Abbrechen',
  'address.del.title': 'Adresse löschen bestätigen?',
  'address.del.content': 'Sind Sie sicher, dass Sie diese Lieferadresse löschen möchten?',

  'payment.method': 'Zahlungsmethode',

  // Einkaufswagen
  'shopping.title': 'Warenkorb',
  'shopping.back.buy': 'Zurück zum Sondereinkauf',
  'shopping.start.shopping': 'Einkaufen beginnen',

  // Mitglied
  'member.account': 'Personal Center',
  'member.overview': 'Übersicht',
  'member.orders': 'Bestellungen',
  'member.payment': 'Meine Brieftasche',
  'member.address': 'Lieferadresse',
  'member.wishlist': 'Wunschliste',
  'member.followlist': 'Followerliste',
  'member.message.center': 'Nachrichtenzentrum',
  'member.setting': 'Persönliche Einstellungen',
  'member.shop.info': 'Shop-Informationen',
  'member.shop.setting': 'Shop-Einstellungen',
  'member.order.notify': 'Neue Bestellung benachrichtigen',
  'member.order.tips': 'Durch Senden einer E-Mail an die E-Mail des Händlerkontos',

  // Member.Order
  'member.order.title': 'Meine Bestellung',
  'member.order.view.all': 'Alle anzeigen',
  'member.order.all': 'Alle',
  'member.order.unpaid': 'Unbezahlt',
  'member.order.paid': 'Bezahlt',
  'member.order.procurement': 'Ausstehende Beschaffung',
  'member.order.seller.paid': 'Gekauft',
  'member.order.processing': 'Ausstehend',
  'member.order.shipped': 'versendet',
  'member.order.completed': 'Abgeschlossen',
  'member.order.refund': 'Erstattet',
  'member.order.all.time': 'Alle',
  'member.order.empty': 'Noch keine Bestellung',
  'member.order.date': 'Datum',
  'member.order.purchase.date': 'Kauf',
  'member.order.cpoy': 'Kopieren',
  'member.order.id': 'Bestellung',
  'member.order.detail': 'Bestelldetails',
  'member.order.logistics': 'Logistikinformationen',

  // mitglied.zahlung
  'member.payment.title': 'Meine Brieftasche',
  'member.wallet.balance': 'Wallet-Guthaben',
  'member.crypto.recharge': 'Aufladen',
  'member.crypto.withdrawal': 'Auszahlen',
  'member.crypto.bank': 'Bankkarte',
  'member.wallet.record': 'Coin-Einzahlungsaufzeichnung',
  'member.bankcard.record': 'Bankkartendatensatz',
  'member.withdrawal.record': 'Auszahlungsaufzeichnung',
  'member.income.record': 'Einkommensnachweis',
  'member.transaction.record': 'Transaktionsdatensatz',
  'member.wallet.freeze': 'Guthaben einfrieren',
  'member.wallet.profit': 'Geschätzte Einnahmen',

  //aufladen
  'recharge.currency': 'Währung',
  'recharge.protocol': 'Protokoll',
  'aufladen.qrcode': 'QR-Code',
  'recharge.address': 'Wallet-Adresse',
  'aufladen.kopieren': 'Adresse kopieren',
  'aufladen.ok.text': 'Bestätigung',
  'recharge.cancel.text': 'Stornieren',

  //Bank
  'bank.recharge.title': 'Bankkartenaufladung',
  'bank.title': 'Bankname',
  'bank.name': 'Name des Zahlungsempfängers',
  'bank.account': 'Empfangskonto',
  'bank.routing': 'Bankleitzahl',
  'bank.code': 'Bankleitzahl',
  'bank.bankname': 'Bankname',
  'bank.bankaddress': 'Bankadresse',
  'bank.ok.text': 'Weiter',
  'bank.ok.prev': 'Vorheriger Schritt',
  'bank.submit': 'Jetzt einreichen',
  'bank.amount': 'Aufladebetrag',
  'bank.amount.desc': 'Bitte geben Sie den Aufladebetrag ein',
  'bank.type': 'Typ',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Belegnummer',
  'bank.receipt.number.desc': 'Bitte geben Sie die Belegnummer der Transaktion ein',
  'bank.credential.picture': 'Berechtigungsbild',
  'bank.credential.picture.desc': 'Bitte laden Sie das Ausweisbild hoch',
  'bank.remark': 'Bemerkung',
  'bank.upload': 'Bild hochladen',
  'bank.text.title': 'Notizen',
  'bank.text.t1': '1.Benötigen Sie keine Bemerkungen für die Überweisung, lassen Sie es leer',
  'bank.text.t2': '2.Wählen Sie für Haushalte in Hongkong statt China Hongkong aus',
  'bank.text.t3': '3.Amerikanische Überweisung, europäische Überweisung an Wochentagen vor 15:00 Uhr, Überweisung ist während der Bankarbeitszeiten möglich!',
  'bank.text.t4': '4.Die Zahlungszeit ist T+1, und die späteste Zahlung ist T+3 in Europa',
  'bank.text.t5': '5.Informieren Sie die Plattform, bevor Sie Geld überweisen, um zu bestätigen, ob das Konto verfügbar ist.Wenn das Konto geschlossen wird, wird keine Entschädigung gezahlt.',
  'bank.text.t6': '6.Einzahlung per Bankkarte. Kundendienstinformationen haben Vorrang.',

  //Bankliste
  'bank.list.title': 'Bankname',
  'bank.list.code': 'Bankleitzahl',
  'bank.list.amount': 'Überweisungsbetrag',
  'Bank.Listennummer': 'Quittungsnummer',
  'bank.list.img': 'Gutscheinbild',
  'bank.list.status': 'Status',
  'bank.list.time': 'Zeit',
  'bank.list.status1': 'Wird überprüft',
  'bank.list.status2': 'Bestanden',
  'bank.list.status3': 'Abgelehnt',

  // Rückzug
  'withdrawal.address.desc': 'Bitte geben Sie die Wallet-Adresse ein!',
  'withdrawal.number': 'Nummer',
  'withdrawal.real.number': 'tatsächliche Ankunft',
  'withdrawal.number.desc': 'Bitte geben Sie den Auszahlungsbetrag ein!',
  'withdrawal.btn.all': 'Alle',
  'withdrawal.balance': 'Guthaben',
  'withdrawal.commission': 'Provision',
  'withdrawal.actual.amount': 'Konto',
  'withdrawal.notice': 'Kündigung',
  'withdrawal.notice.text': 'Bitte bestätigen Sie vor der Übertragung, dass die Informationen zur Empfangsadresse korrekt sind.Sobald die Vermögenswerte übertragen wurden, können sie nicht mehr zurückgegeben werden.',
  'withdrawal.notice.content': '{name}({cp_name}) Provision: aktueller Marktwert {fee_rate} %/Transaktion, Mindeststandard: {fee_min} {name}/Transaktion',
  'withdrawal.submit': 'Einreichen',
  'withdrawal.choice': 'Wählen Sie eine Kryptowährung',
  'withdrawal.yzm': 'Bestätigungscode',
  'withdrawal.fs': 'Senden',
  'withdrawal.qsryzm': 'Bitte geben Sie den E-Mail-Bestätigungscode ein',

  // erhalten
  'recive.method': 'Protokoll',
  'recive.amount': 'Menge',
  'recive.address': 'Adresse',
  'recive.date': 'Uhrzeit',
  'recive.status': 'Status',
  'recive.create.at': 'Transaktionszeit',
  'recive.type': 'Typ',
  'recive.befor': 'Vor der Transaktion',
  'recive.balance': 'Guthaben',
  'recive.freeze': 'Einfrieren',
  'recive.review': 'Wird überprüft',
  'recive.success': 'Bestanden',
  'recive.reject': 'Abgelehnt',

  // werben
  'advertise.title': 'Werbezentrum',
  'advertise.shop.title': 'Shop-Promotion',
  'advertise.shop.status': 'Status',
  'advertise.shop.expired': 'Abgelaufen',
  'advertise.shop.promotion': 'Promotion',
  'advertise.shop.expire.date': 'Ablaufdatum',
  'advertise.shop.renew': 'Anzeigenerneuerung',
  'advertise.shop.payable': 'Zu zahlender Betrag',
  'advertise.shop.explanation': 'Anzeigenerläuterung',
  'werben.shop.text': 'Beteiligen Sie sich an Plattform-Werbeaktionen, erhöhen Sie die Präsenz im Geschäft und fördern Sie Transaktionsbestellungen',
  'advertise.shop.paynow': 'Sofort verlängern',
  'advertise.shop.modal.title': 'Zahlung bestätigen',
  'advertise.shop.modal.desc': 'Zahlung der Aktionsgebühr bestätigt',
  'advertise.shop.modal.btn': 'Zahlung bestätigen',

  //Wunschzettel
  'wishlist.title': 'Wunschliste',
  'wishlist.delete': 'Löschen',
  'wishlist.orders': 'Verkäufe',

  //Liste folgen
  'followlist.title': 'Folgeliste',
  'followlist.delete': 'Löschen',
  'followlist.follow': 'Folgen',

  // speichern
  'store.dashboard': 'Dashboard',
  'store.products': 'Produkte',
  'store.products.list': 'Produktliste',
  'store.products.reviews': 'Produktbewertungen',
  'store.orders': 'Bestellungen',
  'store.wallet': 'Brieftasche',
  'store.message': 'Message Center',
  'store.setting': 'Einstellungen',
  'store.order.total.profit': 'Geschätzte Gesamtbetriebseinnahmen',

  //Armaturenbrett
  'dashboard.store.hour.views': 'Besuche in Echtzeit',
  'dashboard.store.today.views': 'Heutige Aufrufe',
  'dashboard.product.total': 'Gesamtprodukt',
  'dashboard.product.today': 'Heute hinzugefügt',
  'dashboard.order.total': 'Bestellsumme',
  'dashboard.sales.total': 'Geschätzter Umsatz',
  'dashboard.sales.real': 'Echte Verkäufe',
  'dashboard.sales.pay': 'Gesamtauszahlung',
  'dashboard.sales.profit': 'Gesamtgewinn',
  'dashboard.commission.total': 'Gesamtumsatz',
  'dashboard.commission.today': 'Heute Einnahmen',
  'dashboard.order.sales': 'Produktverkäufe',
  'dashboard.sales.list': 'Verkaufsrangliste',
  'dashboard.goods.cate.rate': 'Produktkategorieverhältnis',
  'dashboard.goods.wish': 'Like-Waren-Ranking',
  'dashboard.januar': 'Januar',
  'dashboard.februar': 'Februar',
  'dashboard.march': 'März',
  'dashboard.april': 'April',
  'dashboard.may': 'Mai',
  'dashboard.juni': 'Juni',
  'dashboard.juli': 'Juli',
  'dashboard.august': 'August',
  'dashboard.september': 'September',
  'dashboard.october': 'Oktober',
  'dashboard.november': 'November',
  'dashboard.december': 'Dezember',

  // Produkte
  'products.add.new': 'Neues Produkt hinzufügen',
  'products.add.from.warehouse': 'Produkte aus dem Lager hinzufügen',
  'products.delete': 'Löschen',
  'products.add': 'Hinzufügen',
  'products.table.img': 'Bild',
  'products.table.name': 'Name',
  'products.table.category': 'Kategorie',
  'products.table.wish': 'gefällt mir',
  'products.table.stock': 'Lager',
  'products.table.price': 'Preis',
  'products.table.profit': 'Gewinn',
  'products.table.action': 'Aktion',
  'products.search.category': 'Suche nach Kategorie',
  'products.back.product': 'Zurück zur Produktliste',
  'products.total': 'Produkte insgesamt',
  'products.ja': 'Ja',
  'products.no': 'Abbrechen',
  'products.batch.add': 'Stapel hinzufügen',
  'products.ask.add': 'Sind Sie sicher, dass Sie Produkte hinzufügen möchten?',
  'products.batch.delete': 'Stapel löschen',
  'products.ask.delete': 'Möchten Sie das Produkt wirklich löschen?',
  'products.top': 'Oben',
  'products.syzd': 'Startseitenanfang',
  'products.zdwz': 'Spitzenposition',
  'products.t1': 'Werbung',
  'products.t2': 'Bevorzugt',
  'products.t3': 'Boutique',
  'products.t4': 'keine',

  // Bewertungen
  'reviews.title': 'Produktbewertungen',
  'reviews.product.name': 'Produkt',
  'reviews.user.name': 'Benutzer',
  'reviews.star': 'Bewertung',
  'reviews.comment': 'Inhalt überprüfen',
  'reviews.sku': 'Produktspezifikationen',
  'reviews.imgs': 'Fotogalerie',
  'reviews.created': 'Datum',

  // speichern.bestellung
  'store.order.purchase': 'Jetzt kaufen',
  'store.order.purchase.desc': 'Sind Sie sicher, dass Sie für dieses Produkt bezahlen möchten?',
  'store.order.purchase.yes': 'sofort bezahlen',
  'store.order.purchase.no': 'Stornieren',
  'store.order.desc': 'Für den Kauf dieser Bestellung ist eine Zahlung erforderlich, und Sie erhalten Vorteile, nachdem die Bestellung abgeschlossen wurde.',
  'store.order.no': 'Bestellnummer',
  'store.order.number': 'Menge',
  'store.order.buyer': 'Käufer',
  'store.order.total': 'Gesamtbetrag',
  'store.order.will.earning': 'Verdienen',
  'store.order.profit': 'Gewinn',
  'store.order.dividends': 'Dividenden',
  'store.order.payment.status': 'Zahlungsstatus',
  'store.order.seller.buy.status': 'Kaufstatus',
  'store.order.status': 'Bestellstatus',
  'store.order.date': 'Bestelldatum',
  'store.order.purchase.date': 'Kaufdatum',
  'store.order.action': 'Aktion',
  'store.order.purchase.amount': 'Kaufbetrag',

  //Einkommen
  'income.create.at': 'Rekordzeit',
  'income.order.sn': 'Bestellnummer',
  'income.realpay': 'Bestellbetrag',
  'Einkommen.Gewinn': 'Einkommen',

  //Einstellung
  'setting.avatar': 'Business-Avatar',
  'setting.upload': 'Hochladen',
  'setting.shop.name': 'Shop-Name',
  'setting.shop.phone': 'Telefon kontaktieren',
  'setting.shop.address': 'Geschäftsadresse',
  'setting.shop.save': 'Speichern',
  'setting.upload.pic': 'Bild hochladen',
  'setting.send.pic': 'Bild senden',

  //Verkäufer
  'beseller.title': 'Bewerben Sie sich als Händler',
  'beseller.account': 'Kontoinformationen',
  'beseller.store': 'Store-Informationen',
  'beseller.store.name': 'Geschäftsname',
  'beseller.store.address': 'Geschäftsadresse',

  //Zuhause speichern
  'store.home.title': 'Homepage-Einstellungen',
  'store.home.topimg': 'Bild oben',
  'store.home.banner': 'Karussell',
  'store.home.up3': 'Bemerkungen: Mindestens 3 Uploads',
  'store.upload.more': 'mehrere Bilder hochladen',
  'store.home.columns': 'Spalten',
  'store.home.bgimg': 'Hintergrundbild',
  'store.goods.remark': 'Anmerkung: 5 Artikel pro Zeile, maximal 10 Artikel pro Abschnitt',
  'store.home.select': 'Produkt auswählen',
  'store.home.add': 'Spalte hinzufügen',

  //Persönliche Angaben
  'setting.update.user': 'persönliche Informationen',
  'setting.update.info': 'Informationen ändern',
  'setting.user.avatar': 'Benutzer-Avatar',
  'setting.user.nickname': 'Benutzer-Spitzname',
  'setting.user.nickname.desc': 'Bitte Benutzernamen eingeben',

  'setting.safe.title': 'Sicherheitshinweise',
  'setting.user.passwd': 'Passwort ändern',
  'setting.passwd.title1': 'Altes Passwort',
  'setting.passwd.desc1': 'Bitte geben Sie das alte Passwort ein',
  'setting.passwd.title2': 'Neues Passwort',
  'setting.passwd.desc2': 'Bitte geben Sie ein neues Passwort ein',
  'setting.passwd.title3': 'Passwort bestätigen',
  'setting.passwd.desc3': 'Bitte geben Sie das Bestätigungspasswort ein',
  'setting.invitation': 'Freunde einladen',
  'setting.copy': 'Kopieren',

  'adv.add.products': 'Werbeartikel hinzufügen',
  'adv.list.title': 'Produktwerbung',
  'adv.type': 'Werbeaktionstyp',
  'adv.begin.time': 'Aktionszeit',
  'adv.end.time': 'Endzeit',
  'adv.status': 'Status',
  'adv.status.s1': 'Werbung',
  'adv.status.s2': 'Ende',
  'adv.add.back': 'Zur beworbenen Liste zurückkehren',
  'adv.recharge': 'Aktionspunkte aufladen',
  'adv.select.goods': 'Waren auswählen',
  'adv.select.btn': 'Beworbene Produkte',
  'adv.recharge.title': 'Aktionspunkte aufladen',
  'adv.recharge.balance': 'Punktestand',
  'adv.point': 'point',
  'adv.point.rate': 'Punkteverhältnis',
  'adv.recharge.amount': 'Aufladebetrag',
  'adv.recharge.input.amount': 'Aufladebetrag eingeben',
  'adv.select.mod': 'Panel auswählen',
  'adv.mod1': 'Empfohlene Spalten',
  'adv.mod2': 'Bevorzugte Spalte',
  'adv.mod3': 'Heißverkaufte Kolumne',
  'adv.mod4': 'Ausgezeichnete Liste',
  'adv.mod5': 'Klassifizierungsliste',
  'adv.mod6': 'Detaillierte Werbung',
  'adv.ge': 'Menge',
  'adv.xs': 'Stunde',
  'adv.xzbk': 'Platte auswählen',
  'adv.syme': 'Verbleibendes Kontingent',
  'adv.tgsc': 'Aktionsdauer',
  'adv.tgjg': 'Aktionspreis',
  'adv.syye': 'Aktueller Saldo',
  'adv.tgye': 'Aktionsguthaben',
  'adv.yj1': 'erwartet',
  'adv.yj2': 'Beförderung starten',
  'adv.pay.point': 'Verbrauchsstellen',

  'wallet.ykcsxf': 'Die Bearbeitungsgebühr wurde abgezogen',
  'wallet.sxf': 'Servicegebühr',
  'wallet.zxje': 'Mindestbetrag',
  'wallet.24hxe': '24-Stunden-Limit',
  'wallet.desc.text': 'Unter keinen Umständen wird der Beamte Sie auffordern, Geld auf ein Konto zu überweisen, noch wird er Sie nach einem Bestätigungscode fragen. Beteiligen Sie sich nicht an illegalen Aktivitäten wie dem Kauf im Namen anderer, Geldwäsche und illegalem Fundraising und hüten Sie sich vor Online-Betrug',
  'wallet.cunbi.text': 'Sie können {name} nur an diese Adresse hinterlegen, andere Assets werden nicht abgerufen',

  'credit.title': 'Kreditlimit',
  'credit.ed': 'Quote',
  'credit.hk': 'Rückzahlung',
  'credit.kyye': 'Verfügbares Guthaben',
  'credit.dqqk': 'Aktuelle Zahlungsrückstände',
  'credit.hkje': 'Rückzahlungsbetrag',
  'credit.hkje.desc': 'Bitte geben Sie den Rückzahlungsbetrag ein',
  'credit.cgje': 'Kaufbetrag',
  'credit.qrz': 'Zertifizierung',
  'credit.srxyj': 'Kreditzahlungsbetrag eingeben',

  'store.gzl': 'folgen',
  'store.wxz': 'Unbegrenzt',

  'auth.smrz': 'Echtnamen-Authentifizierung',
  'auth.rzlx': 'Typ',
  'auth.sfzm': 'Ausweisvorderseite',
  'auth.sffm': 'Die Rückseite des Personalausweises',
  'auth.zsxm': 'richtiger Name',
  'auth.zjhm': 'Dokumentnummer',
  'auth.yyzz': 'Geschäftslizenz',
  'auth.gsmc': 'Firmenname',
  'auth.shz': 'Prüfung',
  'auth.ytg': 'Bestanden',
  'auth.ybh': 'Abgelehnt',
  'auth.zt': 'Status',
  'auth.gr': 'Persönlich',
  'auth.gs': 'Unternehmen',
  'auth.ljtj': 'Authentifizierung beantragen',
  'auth.wrz': 'Nicht authentifiziert',

  'credit.qts': 'Beschreibung anzeigen',
  'store.spss': 'Warenobergrenze',
  'store.dpfh': 'Store-Dividenden',
  'store.qydj': 'Eigenkapitalniveau',
  'store.level': 'level',
  'store.jian': 'Stücke',

  'order.notify.email': 'Aufgrund der E-Mail-Risikokontrolle werden häufig Bestellerinnerungs-E-Mails der Plattform gesendet und abgefangen. Um sie rechtzeitig zu erhalten, wird empfohlen, {email} zum Login hinzuzufügen E-Mail-Whitelist',

  'setting.sjxx': 'Informationen',
  'setting.smrz': 'Authentifizierung',
  'setting.aqzx': 'Sicherheit',
  'setting.tzxx': 'Benachrichtigung',
  'setting.dpzx': 'Dekoration',
  'setting.sjqy': 'Eigenkapital',
  'setting.sjdjqy': 'Beschreibung der Vorteile auf Händlerebene',

  'setting.fhbky': 'Die aktuelle Dividende ist nicht verfügbar',
  'setting.spgzyzy': 'Regeln und Richtlinien speichern',

  'desc.dqqy': 'Aktuelles Eigenkapital',
  'desc.xjqy': 'Untergeordnete Interessen',
  'desc.sjqy_0': 'Kreditlimit speichern',
  'desc.sjqy_1': 'Dividenden speichern',
  'desc.sjqy_2': 'Anzahl der Produkte, die veröffentlicht werden können',

  'share.yqlb': 'Einladungsliste',
  'share.zmj': 'Gesamtverkäufer',
  'share.zdd': 'Gesamtbestellung',
  'share.zsy': 'Gesamteinkommen',
  'share.wcl': 'unbearbeitet',
  'share.ycl': 'Verarbeitet',
  'share.ywc': 'Abgeschlossen',
  'share.dpmc': 'Geschäftsname',
  'share.ddl': 'Bestellmenge',
  'share.jjsy': 'Maklereinnahmen',
  'share.zts': 'Gesamtzahl der Artikel',

  'chat.state': 'State',
  'chat.zx': 'Online',
  'chat.ljz': 'Verbinden',

  'bind.wallet.title': 'Abhebungsadresse',
  'bind.address.title': 'Adresse hinzufügen',
  'bind.bj': 'Bearbeiten',
  'bind.sc': 'Löschen',
  'bind.qrsc': 'Sind Sie sicher, dass Sie diese Adresse löschen möchten?',
  'bind.qbdz': 'Wallet-Adresse',
  'bind.tjsj': 'Zeit hinzufügen',
  'bind.cz': 'Operation',
  'withdrawal.address.choice': 'Bitte wählen Sie die Auszahlungsadresse!',

  'order.sxsj': 'Effektive Zeit',
  'order.ljsj': 'sofort wirksam',
  'order.dssj': 'Zeitlich wirksam',

  'store.st.zt': 'Store-Status',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Ihr Shop ist normal, bitte machen Sie weiter so',
  'store.st.yc': 'Ausnahme',
  'store.st.yc.desc': 'Ihr Shop hat einige ungewöhnliche Bestellungen oder Kundenbeschwerden erhalten',
  'store.st.dj': 'Einfrieren',
  'store.st.dj.desc': 'Ihr Geschäft birgt ein gewisses Betriebsrisiko und die Gelder sind eingefroren',
  'store.st.jy': 'deaktiviert',
  'store.st.jy.desc': 'Ihr Shop hat gegen die plattformbezogenen Vereinbarungen und Regeln verstoßen und wurde deaktiviert.',

  'task.sy': 'Homepage',
  'task.rwlbo': 'Aufgabenliste',
  'task.rwxqo': 'Aufgabendetails',
  'task.ljcy': 'Jetzt teilnehmen',
  'task.gdrw': 'Mehr Aufgaben',
  'task.rwlb': 'Liste der Greifaufgaben',
  'task.qbrw': 'Alle Aufgaben',
  'task.wcyd': 'Ich habe teilgenommen an',
  'task.qdz': 'Bestellungen erhalten',
  'task.ywc': 'Abgeschlossen',
  'task.djy': 'zu handeln',
  'task.ddje': 'Bestellbetrag',
  'task.cyrs': 'Anzahl der Teilnehmer',
  'task.ewsy': 'Zusätzliche Vorteile',
  'task.jzsj': 'Frist',
  'task.ckxq': 'Details anzeigen',
  'task.rwxq': 'Aufgabendetails',
  'task.cylb': 'Teilnahmeliste',
  'task.qdcyrw': 'Sind Sie sicher, dass Sie an dieser Bestellaufgabe teilnehmen möchten?',
  'task.sd': 'Ja',
  'task.bue': 'nein',

  'task.wdsy': 'Umsatz',
  'task.wdfh': 'Dividenden',
  'task.wdqd': 'Bestellung abrufen',
  'task.dd.leixin': 'Auftragstyp',
  'task.dd.qdrw': 'Schnellbestellaufgabe',
  'task.dd.ptdd': 'Gewöhnliche Reihenfolge',

  'flow.lltg': 'Flow Promotion',
  'flow.gmll': 'Datenpaket kaufen',
  'flow.dqtc': 'Aktuelles Paket',
  'flow.tc1': 'Paket',
  'flow.sy2': 'verbleibend',
  'flow.tian3': 'Tag',
  'flow.jlzs': 'Gesamtzahl der Datensätze',
  'flow.lltc': 'Flow-Paket',
  'flow.ll': 'flow',
  'flow.gmsj': 'Kaufzeit',
  'flow.tcjg': 'Paketpreis',
  'flow.sjzf': 'tatsächliche Zahlung',
  'flow.tcsm': 'Paketbeschreibung',
  'flow.tcsm.sm': 'Der Kauf dieses Pakets erhöht die Bekanntheit des Shops und steigert den Kundenverkehr. Um mehr Bestellungen zu erhalten und den Ladenumsatz zu steigern. ',

  'flow.tcmc': 'Paketname',
  'flow.gmsc': 'Kaufdauer',
  'flow.zje': 'Gesamtbetrag',
  'flow.jzsj': 'Frist',
  'flow.gmrq': 'Kaufdatum',
  'flow.tian1': 'Tag',
  'flow.zhou1': 'Woche',
  'flow.yue1': 'Monat',

  'share.dj': 'level',
  'share.hz': 'Übertragen',
  'share.hzd': 'Übertragen an',
  'share.qbye': 'Wallet-Guthaben',
  'share.tgje': 'Aktionsbetrag',
  'share.hzje': 'Überweisungsbetrag',
  'share.qsrhzje': 'Bitte geben Sie den Überweisungsbetrag ein',

  '_inv._title': 'Einladungsbeschreibung',
  '_inv._t1': '1. Einladung und eingeladene Beziehung',
  '_inv._desc1': 'A lädt B ein, B lädt C ein, C lädt D ein. Die höchste Stufe ist 4, wenn D E erneut einlädt, dann hat E nichts mit A zu tun. ',
  '_inv._t2': '2. Der Einladende erhält eine Belohnung',
  '_inv._desc2': 'A erhält 4 % der durch Auftrag B erledigten Gesamtmenge, A erhält 2 % der durch Auftrag C erledigten Gesamtmenge und A erhält 1 % der durch Auftrag D erledigten Gesamtmenge',

  '_index._gm': 'Kauf',
  '_index._sm': 'Beschreibung: Ladenpräsenz erhöhen und Traffic anziehen',

  '_n.dlyzc': 'Anmeldung und Registrierung',
  '_n.hy4': 'Es gibt noch 4 weitere',
  '_n.ckgd': 'Mehr anzeigen',
  '_n._cxhzk': 'Aktionen und Rabatte',
  '_n._cwyp': 'Haustierbedarf',
  '_n._tjsp': 'Sonderangebot',
  '_n._rmfl': 'Beliebte Kategorien',
  '_n._tjsp2': 'Empfohlene Produkte',
  '_n._rxsp': 'Empfohlene Produkte',
  '_n._rmsp': 'Hot Items',
  '_n._gdsp': 'Weitere Produkte',
  '_n._yzsj': 'Qualitätshändler',

  '_n._zxsc': 'Online-Mall',
  '_n._fkyys': 'Zahlung und Versand',
  '_n._xsgz': 'Verkaufsregeln',
  '_n._hhjth': 'Umtausch und Rückgabe',
  '_n._lxwm': 'Kontaktieren Sie uns',
  '_n._sczc': 'Mall-Richtlinie',
  '_n._tkytj': 'Allgemeine Geschäftsbedingungen',
  '_n._tuzc': 'Rückgaberecht',
  '_n._zczc': 'Support-Richtlinie',
  '_n._yszc': 'Datenschutzerklärung',
  '_n._scjj': 'Mall-Einführung',
  '_n._ljgm': 'Jetzt kaufen',

  '_n2._rxsp': 'Heißverkaufte Artikel',
  '_n2._pl': 'Kommentar',
  '_n2._sjxx': 'Geschäftsinformationen',
  '_n2._rhsh': 'So erhalten Sie die Ware',
  '_n2._xsxd': 'Online-Bestellung',
  '_n2._jscldd': 'Spezieller Kundenservice',
  '_n2._mfps': 'Kostenloser Versand',
  '_n2._2xsfh': 'Blitzlieferung',
  '_n2._1d5tdd': 'Ankunft innerhalb von 1-5 Tagen',
  '_n2._tkzd': 'Express Mail',
  '_n2._ckfh': 'Aus Lager versenden',
  '_n2._zcps': 'Lager in der Nähe',
  '_n2._jsd': 'Empfangspunkt',
  '_n2._qsjps': 'Weltweite Lieferung',
  '_n2._thtj': 'Rückgabebedingungen',
  '_n2._thtjsm': 'Innerhalb von 14 Tagen ab Kaufdatum können Sie den Artikel umtauschen oder zurückgeben',
  '_n2._gycp': 'Über das Produkt',
  '_n2._yc': 'Ausblenden',
  '_n2._tiao': 'Tiao',
  '_n2._sypl': 'Alle Kommentare',
  '_n2._lxfk': 'Feedback hinterlassen',
  '_n2._xzspgg': 'Bitte Produktspezifikationen auswählen',
  '_n2._qxdl': 'Bitte melden Sie sich zuerst an',
  '_n2._rnsj': 'Wie Sie sehen können',
  '_n2._rx': 'Heißer Verkauf',

  '_n3._qrdd': 'Bestellung bestätigen',
  '_n3._njsp': '{num} Elemente',
  '_n3._ddzf': 'Zahlung anordnen',
  '_n3._wydlsp': 'Willkommen beim Anmelden im Store',

  '_n3._sjwfgm': 'Händler kann keine Waren kaufen',
  '_n3._sjbnlt': 'Unternehmen können nicht chatten',
  '_chat._hc': 'Zurückziehen',

  '_zc._sryxdz': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
  '_zc._yxgscw': 'E-Mail-Formatfehler',
  '_zc._qsrmm': 'Bitte Passwort eingeben',
  '_zc._lcmmbxd': 'Zwei Passwörter sind inkonsistent',
  '_zc._hdyzwtg': 'Bitte über den Schieberegler überprüfen',
  '_zc._qsryqm': 'Bitte geben Sie den Einladungscode ein',
  '_zc._yhzh': 'Benutzerkonto registrieren',
  '_zc._qzc': 'Zur Registrierung gehen',
  '_zc._srdpm': 'Bitte geben Sie den Shopnamen ein',

  '_zc._gszz': 'Firmenqualifikation',
  '_zc._ptzz': 'Plattformbezogene Qualifikationen',

  '_dd._tijd': 'Bestellung absenden',
  '_dd._xzdz': 'Bitte wählen Sie die Lieferadresse aus',
  '_dd._zfz': 'Zahlung läuft',

  '_wd._zf': 'Zahlung',
  '_wd._cz': 'Aufladen',
  '_wd._txsq': 'Auszahlungsantrag',
  '_wd._ddsy': 'Auftragseinkommen',
  '_wd._cgzf': 'Kauf',
  '_wd._txbh': 'Ablehnen',
  '_wd._txtg': 'Bestanden',
  '_wd._ddtk': 'Rückerstattung bestellen',
  '_wd._yehz': 'Übertragung',
  '_wd._gmll': 'Traffic kaufen',
  '_wd._zjkc': 'Mietabzug',
  '_wd._fk': 'Gut',
  '_wd._ns': 'Steuer',
  '_wd._bzj': 'Marge',

  '_xxtz._zntz': 'Site-Benachrichtigung',
  '_xxtz._fh': 'return',
  '_xxtz._jzgd': 'Mehr laden',
  '_xxtz._mysj': 'Nicht mehr',

  '_sjsy._jrdd': 'Heutige Bestellung',
  '_sjsy._rxse': 'Tägliche Verkäufe',

  '_sjsy._xh': 'Index',

  '_wd._nbjy': 'Interne Übertragung',
  '_wd._qsruid': 'Bitte geben Sie die UID des Zahlungsempfängers ein',
  '_wd._je': 'Betrag',
  '_wd._qsrhzje': 'Bitte geben Sie den Überweisungsbetrag ein',
  '_wd._skr': 'Zahlungsempfänger',
  '_wd._nbhzsm': 'Um intern an Plattformbenutzer zu überweisen, prüfen Sie bitte sorgfältig die Informationen zum Zahlungsempfänger',

  '_st._spxlphb': 'Produktverkaufsranking',
  '_st._czsp': 'Suche zurücksetzen',

  '_st._pljrgwc': 'Alle beitreten',

  '_sj._yxz': 'Ausgewählt',
  '_sj._hjzje': 'Gesamtgesamtbetrag',
  '_sj._zdcr': 'Automatisch einzahlen',

  '_wd._ddfh': 'Dividende',

  '_dd._csqx': 'Bestellstornierung',

  '_tx._yhktx': 'Bankabhebung',
  '_tx._sryhmc': 'Bitte geben Sie den Banknamen ein',
  '_tx._srskrmc': 'Bitte geben Sie den Namen des Zahlungsempfängers ein',
  '_tx._srskzh': 'Bitte geben Sie die Zahlungskontonummer ein',
  '_tx._sryhluh': 'Bitte geben Sie die Bankleitzahl ein',
  '_tx._sryhdm': 'Bitte geben Sie die Bankleitzahl ein',
  '_tx._sryhdz': 'Bitte geben Sie die Bankadresse ein',
  '_tx._txje': 'Auszahlungsbetrag',
  '_tx._srtxje': 'Bitte geben Sie den Auszahlungsbetrag ein',
  '_tx._txkc': 'Auszahlungsgebühr {_sxf}%',

  '_yye._yyye': 'Monatsverkäufe',
  '_yye._ysy': 'Monatliches Einkommen',

  '_sj._hjzsy': 'Gesamtgesamteinkommen',

  '_yhdl._dl': 'Anmelden',
  '_yhdl._zc': 'Konto erstellen',

  '_zdy._24zrkf': 'Wir sind rund um die Uhr für Sie da',
  '_zdy._zhsz': 'Kontoeinstellungen',
  '_zdy._mfps': 'Für eine begrenzte Zeit können Sie in ausgewählten Regionen kostenlosen Versand innerhalb von zwei Werktagen für Tausende von Artikeln erhalten. ',
  '_zdy._gmsp': 'Neue Produkte kaufen',
  '_zdy._czyh': 'Tolle Angebote, genießen Sie Ihre Lieblingsprodukte',
  '_zdy._xsgw': 'Zeitlich begrenzter Einkauf! Bestellen Sie vor dem 12. Mai um 12:00 Uhr ET für kostenlosen Versand. Mit einem speziellen Auto an Ihre Haustür geliefert! ',
  '_zdy._ssgjc': 'Schlüsselwortsuche eingeben',

  '_zdy._rmjx': 'Deine Top-Tipps',

  '_dd._plcz': 'Batch-Vorgang',
  '_dd._plcg': 'Großkauf',
  '_dd._qgxcgd': 'Bitte überprüfen Sie die Bestellung, die Sie kaufen möchten',
  '_dd._yxddbcg': 'Die ausgewählte Bestellung erfordert keinen Kauf',
  '_dd._qdyplcgm': 'Sind Sie sicher, dass Sie die ausgewählte Bestellung in großen Mengen kaufen möchten? ,Menge:{n}',
  '_dd._qdcg': 'Kauf bestätigen',
  '_dd._qx': 'Abbrechen',
  '_dd._plcgwc': 'Sammelkauf abgeschlossen, erfolgreich: {s}/{t}',
  '_dd._zzcgz': 'Einkauf',
  '_st._czhk': 'Aufladen und zurückzahlen',
  '_st._dkje': 'Kreditbetrag',
  '_st._dkjl': 'Kredithistorie',
  '_st._hkfs': 'Rückzahlung',
  '_st._hkje': 'Rückzahlungsbetrag',
  '_st._jrong': 'Mall-Finanzierung',
  '_st._ljsq': 'sofort bewerben',
  '_st._qrshkje': 'Bitte geben Sie den Rückzahlungsbetrag ein',
  '_st._qsrdkje': 'Bitte geben Sie den Kreditbetrag ein',
  '_st._shz': 'in Prüfung',
  '_st._sqdklx': 'Darlehen',
  '_st._sqhk': 'Beantragen Sie die Rückzahlung',
  '_st._sqhklx': 'Rückzahlung',
  '_st._sqjk': 'ein Darlehen beantragen',
  '_st._sqlx': 'Typ',
  '_st._ybh': 'ablehnen',
  '_st._yjk': 'Geliehen',
  '_st._ytg': 'passieren',
  '_st._zzhk': 'Ausstehende Rückzahlung',

  '_st._dqed': 'Darlehensbetrag',
  '_st._ccdked': 'Darlehenslimit darf nicht überschritten werden',
  '_st._tjsqz': 'Bewerbung wird eingereicht',
  '_st._dklx': 'Interesse',
  '_st._dkll': 'Kreditzinssatz: {n}%',
  '_kdts._ts': 'Geöffnet für {t} Tage',
  '_jltg._tgje': 'Werbeinvestitionen',

  '_st._yqk': 'Rückstand',
  '_st._qb': 'Alle',

  '_spxx._spbm': 'Code',

  '_scrz._yyzz': 'Geschäftslizenz',

  '_sjhb._sjbt': 'Gutschein',
  '_sjhb._kfxd': 'Gutscheinliste',
  '_sjhb._fxlb': 'Liste teilen',
  '_sjdhb._hbje': 'Stückelung',
  '_sjdhb._fxzs': 'Gesamtzahl der Aktien',
  '_sjdhb._fxcz': 'Operation',
  '_sjdhb._fxyhj': 'Teilen',
  '_sjdhb._gqsj': 'Ablaufzeit',
  '_sjdhb._lqsj': 'Abholzeitpunkt',
  '_sjdhb._lqbm': 'Kodierung',
  '_sjdhb._wlq': 'Nicht empfangen',
  '_sjdhb._wsy': 'Nicht verwendet',
  '_sjdhb._ysy': 'verwendet',
  '_sjdhb._ylq': 'Empfangen',
  '_sjdhb._ygq': 'Abgelaufen',
  '_sjdhb._fuzi': 'Kopieren',
  '_sjdhb._lqyhj': 'Gutschein',
  '_sjdhb._lqlqyhj': 'Gutschein erhalten',
  '_sjdhb._yhjbm': 'Gutscheincode',
  '_sjdhb._ljlq': 'Jetzt herunterladen'
}
