export default {
  'nav.account': '个人中心',
  'nav.language': '简体中文',
  'signup.success': '提交成功',

  //error
  'contact.empty': '联系方式不能为空',
  'phone.country.empty': '电话前缀不能为空',
  'phone.empty': '电话不能为空',
  'email.valid': '请输入正确的邮箱',

  // base
  'base.success': '成功',
  'base.save': '保存',
  'base.slide': '请拉动滑条验证',
  'base.send.code': '发送验证码',
  'base.send.time': '({time}) 重发验证码',
  'base.send.success': '发送成功',
  'base.view.more': '浏览更多',
  'base.Language': '语言',
  'base.previous': '上一页',
  'base.next': '下一页',
  'base.total.page': '共 {page} 页',
  'base.go.to.page': '转到页面',
  'base.go': '前往',
  'base.followers': '关注量',
  'base.follow': '关注',
  'base.following': '已关注',
  'base.visit.store': '浏览店铺',
  'base.contact': '联系我们',
  'base.delivery': '预计交货时间:1-7 天',
  'base.search': '搜索',
  'base.search.text': '输入关键字搜索',
  'base.server': '在线客服',

  //index
  'text.categories.title': '商品分类',
  'text.welcome': '欢迎购物!',
  'text.wishlist': '喜欢列表',
  'text.account': '个人中心',
  'text.orders': '订单',
  'text.messages': '消息',
  'text.suggest.title': '独家优惠',
  'text.suggest.content': '只为新的全球至尊会员!',
  'text.super.deals': '顶级产品,令人难以置信的价格.',

  //signup
  'signup.register': '注册',
  'signup.signin': '登录',
  'signup.store.signin': '商家登录',
  'signup.sign.out': '注销',
  'signup.email': '邮箱地址',
  'signup.password': '密码',
  'signup.qr.password': '确认密码',
  'signup.confirm.password': '确认密码',
  'signup.forgot.password': '忘记密码',
  'signup.invitation': '邀请码',
  'signup.char': '字符',
  'signup.contains': '包含数字、字母或符号',
  'signup.qr.contains': '两次密码不一致',
  'signup.create.account': '创建账户',
  'signup.agree': '创建帐户，则同意',
  'signup.member.agreement': '会员协议',
  'signup.and': '与',
  'signup.privacy.policy': '隐私政策',
  'signup.email.code': '邮箱验证',
  'signup.last.step': '最后一步',
  'signup.send.email': '请输入发送至{email}的4位验证码',
  'signup.modify.email': '修改邮箱',
  'signup.verify.email': '验证邮箱',
  'signup.have.store': '有商家账号',
  'signup.goto.signin': '商家登录',
  'signup.no.store': '没有商家账号',
  'signup.goto.store': '注册商家',
  'signup.next': '下一步',
  'signup.your.email': '你的邮箱',
  'signup.code.text': '验证码',
  'signup.submit.signup': '立即注册',
  'signup.smrz': '实名认证',
  'signup.derb': '第二步',
  'signup.qsrxm': '请输入证件姓名',
  'signup.qsrhm': '请输入证件号码',

  //forgot
  'forgot.title': '重置密码',
  'forgot.btn.check': '验证邮箱',
  'forgot.reset.now': '立即重置',

  //store
  'store.info.open': '开店{y}周',

  // footer
  'footer.great.value': '巨大的价值',
  'footer.great.value.desc': '我们为超过1亿件商品提供具有竞争力的价格.',
  'footer.shopping': '全球购物',
  'footer.shopping.desc': '我们向200多个国家和地区发货,我们的网站提供7种语言.',
  'footer.safe.payment': '安全支付',
  'footer.safe.payment.desc': '使用世界上最流行和最安全的支付方式进行支付.',
  'footer.shop.with.confidence': '放心购物',
  'footer.shop.with.confidence.desc': '我们的买家保护政策涵盖您的整个购买过程.',
  'footer.help.center': '帮助中心',
  'footer.help.center.desc': '全天候协助,打造顺畅的购物体验.',
  'footer.terms.conditions': '条款和条件',
  'footer.return.policy': '退货政策',
  'footer.support.policy': '支持政策',
  'footer.privacy.policy': '隐私政策',
  'footer.be.seller': '成为商家',
  'footer.apply.now': '立即申请',
  'footer.stay.connected': '保持联系',

  'footer.about.us': '关于我们',
  'footer.about.company': '公司简介',
  'footer.about.video': '视频介绍',
  'footer.contact': '联系方式',

  'footer.my.account': '我的账户',
  'footer.my.logout': '退出登录',
  'footer.my.order': '订单历史',
  'footer.my.wish': '我的愿望清单',
  'footer.my.join': '成为会员合作伙伴',
  'footer.email': '电子邮箱',
  'footer.gfemail': '官方邮箱',
  'footer.fwemail': '服务邮箱',
  'footer.address': '地址',

  'apply.success': '申请成功',
  'apply.success.desc': '已申请成功,前往登录账户',

  // categories
  'category.title': '相关分类',
  'category.all': '所有分类',

  // detail
  'detail.store.home': '店铺首页',
  'detail.sale.items': '商品列表',
  'detail.recommend': '推荐商品',
  'detail.orders': '销量',
  'detail.quantity': '数量',
  'detail.pieces.available': '可用',
  'detail.delivery': '送货',
  'detail.free.shipping': '免运费',
  'detail.estimated.delivery': '预计到达',
  'detail.days': '天',
  'detail.buy.now': '购买',
  'detail.add.to.cart': '加入购物车',
  'detail.buyer.protection': '买家保护',
  'detail.money.guarantee': '退款保证',
  'detail.refund.desc': '如果物品与描述不符或未送达，可获得全额退款',
  'detail.description': '商品描述',
  'detail.customer.reviews': '顾客评论',
  'detail.specifications': '商品规格',
  'detail.top.selling.products': '畅销商品',
  'detail.recommended.for.you': '为你推荐',
  'detail.sold': '销量',
  'detail.receipt': '确认收货',
  'detail.receipt.title': '确定该订单收货?',
  'detail.receipt.content': '确认后,该订单交易完成',
  'detail.comment': '评论',
  'detail.refund.title': '确认申请退款',
  'detail.refund.content': '确认后，订单将申请退款',
  'detail.sqtk': '申请退款',

  // confirm
  'confirm.shipping.address': '收件地址',
  'confirm.change': '改变',
  'confirm.payment.methods': '支付方式',
  'confirm.summary': '结算',
  'confirm.total.item.costs': '商品合计',
  'confirm.total': '总计',
  'confirm.checkout': '去结算',
  'confirm.place.order': '立即下单',
  'confirm.pay.now': '立即支付',
  'confirm.order.desc': "单击'下订单'后，我确认我已阅读并确认",
  'confirm.order.policy': '所有条款和政策',
  'confirm.payment': '全球购物确保您的信息和付款安全',

  // address
  'address.title': '收件地址',
  'address.default': '默认',
  'address.edit': '编辑',
  'address.delete': '删除',
  'address.new': '创建新地址',
  'address.contact': '联系人',
  'address.address': '地址',
  'address.phone': '电话号码',
  'address.set.default': '设置默认',
  'address.confirm': '确认',
  'address.cancel': '取消',
  'address.del.title': '确认删除地址?',
  'address.del.content': '您确定要删除此送货地址吗?',

  'payment.method': '支付方式',

  // Shopping Cart
  'shopping.title': '购物车',
  'shopping.back.buy': '返回特价购物',
  'shopping.start.shopping': '开始购物',

  // member
  'member.account': '个人中心',
  'member.overview': '概述',
  'member.orders': '订单',
  'member.payment': '我的钱包',
  'member.address': '收货地址',
  'member.wishlist': '喜欢列表',
  'member.followlist': '关注列表',
  'member.message.center': '消息中心',
  'member.setting': '个人设置',
  'member.shop.info': '商铺信息',
  'member.shop.setting': '商铺设置',
  'member.order.notify': '新订单通知',
  'member.order.tips': '通过发送邮件到商家账号邮箱',

  // member.order
  'member.order.title': '我的订单',
  'member.order.view.all': '查看全部',
  'member.order.all': '全部',
  'member.order.unpaid': '未支付',
  'member.order.paid': '已支付',
  'member.order.procurement': '待采购',
  'member.order.seller.paid': '已采购',
  'member.order.processing': '待处理',
  'member.order.shipped': '配送中',
  'member.order.completed': '已完成',
  'member.order.refund': '已退款',
  'member.order.all.time': '全部',
  'member.order.empty': '暂无订单',
  'member.order.date': '日期',
  'member.order.purchase.date': '采购',
  'member.order.cpoy': '复制',
  'member.order.id': '订单',
  'member.order.detail': '订单详情',
  'member.order.logistics': '物流信息',

  // member.payment
  'member.payment.title': '我的钱包',
  'member.wallet.balance': '钱包余额',
  'member.crypto.recharge': '充值',
  'member.crypto.withdrawal': '提现',
  'member.crypto.bank': '银行卡',
  'member.wallet.record': '存币记录',
  'member.bankcard.record': '银行卡记录',
  'member.withdrawal.record': '提现记录',
  'member.income.record': '收益记录',
  'member.transaction.record': '交易记录',
  'member.wallet.freeze': '冻结资金',
  'member.wallet.profit': '预计收益',

  //recharge
  'recharge.currency': '币种',
  'recharge.protocol': '协议',
  'recharge.qrcode': '二维码',
  'recharge.address': '钱包地址',
  'recharge.copy': '复制地址',
  'recharge.ok.text': '确认',
  'recharge.cancel.text': '取消',

  //bank
  'bank.recharge.title': '银行卡充值',
  'bank.title': '银行名称',
  'bank.name': '收款人姓名',
  'bank.account': '收款账号',
  'bank.routing': '银行路由号',
  'bank.code': '银行代码',
  'bank.bankname': '银行名称',
  'bank.bankaddress': '银行地址',
  'bank.ok.text': '下一步',
  'bank.ok.prev': '上一步',
  'bank.submit': '立即提交',
  'bank.amount': '充值金额',
  'bank.amount.desc': '请输入充值金额',
  'bank.type': '类型',
  'bank.usd': '美元',
  'bank.eur': '欧元',
  'bank.receipt.number': '回执单号',
  'bank.receipt.number.desc': '请输入交易回执单号',
  'bank.credential.picture': '凭证图片',
  'bank.credential.picture.desc': '请上传凭证图片',
  'bank.remark': '说明',
  'bank.upload': '上传图片',
  'bank.text.title': '注意事项',
  'bank.text.t1': '1、转账不要任何备注,不填',
  'bank.text.t2': '2、香港户国家选择香港,不要加中国',
  'bank.text.t3': '3、美国汇款,工作日,下午三点前汇欧洲汇款,银行上班时间可汇!',
  'bank.text.t4': '4、到款时间T+1,欧洲最迟T+3到款',
  'bank.text.t5': '5、转款之前告知平台,确定账户是否可用,如果封户，不赔付.',
  'bank.text.t6': '6、通过银行卡入金一切已客服信息为准.',

  //banklist
  'bank.list.title': '银行名称',
  'bank.list.code': '银行代码',
  'bank.list.amount': '转账金额',
  'bank.list.number': '回执单号',
  'bank.list.img': '凭证图片',
  'bank.list.status': '状态',
  'bank.list.time': '时间',
  'bank.list.status1': '审核中',
  'bank.list.status2': '已通过',
  'bank.list.status3': '已驳回',

  // Withdrawal
  'withdrawal.address.desc': '请输入钱包地址!',
  'withdrawal.number': '数量',
  'withdrawal.real.number': '实际到账',
  'withdrawal.number.desc': '请输入提现数量!',
  'withdrawal.btn.all': '全部',
  'withdrawal.balance': '余额',
  'withdrawal.commission': '佣金',
  'withdrawal.actual.amount': '到账',
  'withdrawal.notice': '提示',
  'withdrawal.notice.text': '转账前,请确认收款地址信息正确无误.资产一经转出,不可退回.',
  'withdrawal.notice.content': '{name}({cp_name}) 佣金:当前市值{fee_rate}%/笔,最低标准:{fee_min} {name}/笔',
  'withdrawal.submit': '提交',
  'withdrawal.choice': '选择加密货币',
  'withdrawal.yzm': '验证码',
  'withdrawal.fs': '发送',
  'withdrawal.qsryzm': '请输入邮箱验证码',

  // recive
  'recive.method': '协议',
  'recive.amount': '金额',
  'recive.address': '地址',
  'recive.date': '时间',
  'recive.status': '状态',
  'recive.create.at': '交易时间',
  'recive.type': '类型',
  'recive.befor': '交易之前',
  'recive.balance': '余额',
  'recive.freeze': '冻结',
  'recive.review': '审核中',
  'recive.success': '已通过',
  'recive.reject': '已驳回',

  // advertise
  'advertise.title': '推广中心',
  'advertise.shop.title': '店铺推广',
  'advertise.shop.status': '状态',
  'advertise.shop.expired': '已过期',
  'advertise.shop.promotion': '推广中',
  'advertise.shop.expire.date': '到期时间',
  'advertise.shop.renew': '广告续费',
  'advertise.shop.payable': '应付金额',
  'advertise.shop.explanation': '推广说明',
  'advertise.shop.text': '参与平台广告推广，提升店铺曝光度，促进交易订单量',
  'advertise.shop.paynow': '立即续费',
  'advertise.shop.modal.title': '确认支付',
  'advertise.shop.modal.desc': '确认为支付推广费用',
  'advertise.shop.modal.btn': '确认支付',

  //Wish List
  'wishlist.title': '喜欢列表',
  'wishlist.delete': '删除',
  'wishlist.orders': '销量',

  //Follow List
  'followlist.title': '关注列表',
  'followlist.delete': '删除',
  'followlist.follow': '关注',

  // store
  'store.dashboard': '仪表盘',
  'store.products': '产品',
  'store.products.list': '产品列表',
  'store.products.reviews': '产品回复',
  'store.orders': '订单',
  'store.wallet': '钱包',
  'store.message': '消息中心',
  'store.setting': '设置',
  'store.order.total.profit': '预计营业总收益',

  //dashboard
  'dashboard.store.hour.views': '实时访问量',
  'dashboard.store.today.views': '今日访问量',
  'dashboard.product.total': '产品总量',
  'dashboard.product.today': '今日新增',
  'dashboard.order.total': '订单总量',
  'dashboard.sales.total': '预计营业额',
  'dashboard.sales.real': '实际营业额',
  'dashboard.sales.pay': '总支出',
  'dashboard.sales.profit': '总收益',
  'dashboard.commission.total': '总收益',
  'dashboard.commission.today': '今日收益',
  'dashboard.order.sales': '商品销售量',
  'dashboard.sales.list': '销售排行',
  'dashboard.goods.cate.rate': '商品分类占比',
  'dashboard.goods.wish': '喜欢商品排行',
  'dashboard.january': '1月',
  'dashboard.february': '2月',
  'dashboard.march': '3月',
  'dashboard.april': '4月',
  'dashboard.may': '5月',
  'dashboard.june': '6月',
  'dashboard.july': '7月',
  'dashboard.august': '8月',
  'dashboard.september': '9月',
  'dashboard.october': '10月',
  'dashboard.november': '11月',
  'dashboard.december': '12月',

  // products
  'products.add.new': '添加新产品',
  'products.add.from.warehouse': '从仓库添加产品',
  'products.delete': '删除',
  'products.add': '添加',
  'products.table.img': '图片',
  'products.table.name': '商品名称',
  'products.table.category': '所属分类',
  'products.table.wish': '喜欢',
  'products.table.stock': '库存',
  'products.table.price': '价格',
  'products.table.profit': '收益',
  'products.table.action': '操作',
  'products.search.category': '按分类搜索',
  'products.back.product': '返回产品列表',
  'products.total': '商品总计',
  'products.yes': '是的',
  'products.no': '取消',
  'products.batch.add': '批量增加',
  'products.ask.add': '确定要增加商品吗?',
  'products.batch.delete': '批量删除',
  'products.ask.delete': '确定要删除商品吗?',
  'products.top': '置顶',
  'products.syzd': '首页置顶',
  'products.zdwz': '置顶位置',
  'products.t1': '推广',
  'products.t2': '优选',
  'products.t3': '精品',
  'products.t4': '无',

  // reviews
  'reviews.title': '产品评论',
  'reviews.product.name': '商品',
  'reviews.user.name': '用户',
  'reviews.star': '评分',
  'reviews.comment': '评论内容',
  'reviews.sku': '商品规格',
  'reviews.imgs': '图集',
  'reviews.created': '日期',

  // store.order
  'store.order.purchase': '立即采购',
  'store.order.purchase.desc': '确定要付款采购该产品?',
  'store.order.purchase.yes': '立即支付',
  'store.order.purchase.no': '取消',
  'store.order.desc': '采购该订单需要支付80%的货款，订单完成后获得收益。 ',
  'store.order.no': '订单号',
  'store.order.number': '数量',
  'store.order.buyer': '买家',
  'store.order.total': '总金额',
  'store.order.will.earning': '收入',
  'store.order.profit': '收益',
  'store.order.dividends': '分红',
  'store.order.payment.status': '支付状态',
  'store.order.seller.buy.status': '采购状态',
  'store.order.status': '订单状态',
  'store.order.date': '订单日期',
  'store.order.purchase.date': '采购日期',
  'store.order.action': '操作',
  'store.order.purchase.amount': '采购金额',

  //income
  'income.create.at': '记录时间',
  'income.order.sn': '订单编号',
  'income.realpay': '订单金额',
  'income.profit': '收益',

  //Setting
  'setting.avatar': '商家头像',
  'setting.upload': '上传',
  'setting.shop.name': '店铺名称',
  'setting.shop.phone': '联系电话',
  'setting.shop.address': '店铺地址',
  'setting.shop.save': '保存',
  'setting.upload.pic': '上传图片',
  'setting.send.pic': '发送图片',

  //beseller
  'beseller.title': '申请成为商家',
  'beseller.account': '账号信息',
  'beseller.store': '店铺信息',
  'beseller.store.name': '店铺名称',
  'beseller.store.address': '店铺地址',

  //savehome
  'store.home.title': '首页设置',
  'store.home.topimg': '顶部图',
  'store.home.banner': '轮播图',
  'store.home.up3': '备注：最少上传3张',
  'store.upload.more': '上传多图',
  'store.home.columns': '栏目',
  'store.home.bgimg': '背景图片',
  'store.goods.remark': '备注：每行5个商品，每个板块最多10个商品',
  'store.home.select': '选择商品',
  'store.home.add': '增加栏目',

  //个人信息
  'setting.update.user': '个人信息',
  'setting.update.info': '修改信息',
  'setting.user.avatar': '用户头像',
  'setting.user.nickname': '用户昵称',
  'setting.user.nickname.desc': '请输入用户昵称',

  'setting.safe.title': '安全信息',
  'setting.user.passwd': '修改密码',
  'setting.passwd.title1': '旧密码',
  'setting.passwd.desc1': '请输入旧密码',
  'setting.passwd.title2': '新密码',
  'setting.passwd.desc2': '请输入新密码',
  'setting.passwd.title3': '确认密码',
  'setting.passwd.desc3': '请输入确认密码',

  'setting.invitation': '邀请朋友',
  'setting.copy': '复制',

  'adv.add.products': '增加推广商品',
  'adv.list.title': '商品推广',
  'adv.type': '推广类型',
  'adv.begin.time': '推广时间',
  'adv.end.time': '结束时间',
  'adv.status': '状态',
  'adv.status.s1': '推广中',
  'adv.status.s2': '已结束',
  'adv.add.back': '返回已推广列表',
  'adv.recharge': '充值推广点数',
  'adv.select.goods': '选择商品',
  'adv.select.btn': '推广商品',
  'adv.recharge.title': '充值推广点数',
  'adv.recharge.balance': '点数余额',
  'adv.point': '点',
  'adv.point.rate': '点数比例',
  'adv.recharge.amount': '充值金额',
  'adv.recharge.input.amount': '输入充值金额',
  'adv.select.mod': '选择板块',
  'adv.mod1': '推荐栏目',
  'adv.mod2': '优选栏目',
  'adv.mod3': '热销栏目',
  'adv.mod4': '精品列表',
  'adv.mod5': '分类列表',
  'adv.mod6': '详情推广',
  'adv.ge': '数量',
  'adv.xs': '小时',
  'adv.xzbk': '选择板块',
  'adv.syme': '剩余名额',
  'adv.tgsc': '推广时长',
  'adv.tgjg': '推广价格',
  'adv.syye': '当前余额',
  'adv.tgye': '推广余额',
  'adv.yj1': '预计 ',
  'adv.yj2': '开始推广',
  'adv.pay.point': '消费点数',

  'wallet.ykcsxf': '已扣除手续费',
  'wallet.sxf': '手续费',
  'wallet.zxje': '最小金额',
  'wallet.24hxe': '24小时限额',
  'wallet.desc.text': '官方在任何情况下都不会要求您向某一账户转账，也不会向您索要验证码。请勿参与代买，洗钱、非法集资等违法行为，谨防网络诈骗',
  'wallet.cunbi.text': '您只能向此地址充入{name},充入其他资产将无法找回',

  'credit.title': '信用额度',
  'credit.ed': '额度',
  'credit.hk': '还款',
  'credit.kyye': '可用余额',
  'credit.dqqk': '当前欠款',
  'credit.hkje': '还款金额',
  'credit.hkje.desc': '请输入还款金额',
  'credit.cgje': '采购金额',
  'credit.qrz': '去认证',
  'credit.srxyj': '输入信用支付金额',

  'store.gzl': '关注量',
  'store.wxz': '无限制',

  'auth.smrz': '实名认证',
  'auth.rzlx': '类型',
  'auth.sfzm': '身份证正面',
  'auth.sffm': '身份证反面',
  'auth.zsxm': '真实姓名',
  'auth.zjhm': '证件号码',
  'auth.yyzz': '营业执照',
  'auth.gsmc': '公司名称',
  'auth.shz': '审核中',
  'auth.ytg': '已通过',
  'auth.ybh': '已驳回',
  'auth.zt': '状态',
  'auth.gr': '个人',
  'auth.gs': '企业',
  'auth.ljtj': '申请认证',
  'auth.wrz': '未认证',

  'credit.qts': '查看说明',
  'store.spss': '商品上限',
  'store.dpfh': '店铺分红',
  'store.qydj': '权益等级',
  'store.level': '级',
  'store.jian': '件',

  'order.notify.email': '由于邮箱风控,平台订单提醒邮件发送频繁会被拦截,为了及时收到,建议将{email}添加到登录邮箱白名单',

  'setting.sjxx': '商家信息',
  'setting.smrz': '实名认证',
  'setting.aqzx': '安全中心',
  'setting.tzxx': '通知信息',
  'setting.dpzx': '装修首页',
  'setting.sjqy': '商家权益',
  'setting.sjdjqy': '商家等级权益说明',

  'setting.fhbky': '当前分红不可用',
  'setting.spgzyzy': '商铺规则与指引',

  'desc.dqqy': '当前权益',
  'desc.xjqy': '下级权益',
  'desc.sjqy_0': '店铺信用额度',
  'desc.sjqy_1': '店铺分红',
  'desc.sjqy_2': '可发布商品数量',

  'share.yqlb': '邀请列表',
  'share.zmj': '总卖家',
  'share.zdd': '总订单',
  'share.zsy': '总收益',
  'share.wcl': '未处理',
  'share.ycl': '已处理',
  'share.ywc': '已完成',
  'share.dpmc': '店铺名称',
  'share.ddl': '订单量',
  'share.jjsy': '经纪收益',
  'share.zts': '总条数',

  'chat.state': '状态',
  'chat.zx': '在线',
  'chat.ljz': '连接中',

  'bind.wallet.title': '提币地址',
  'bind.address.title': '添加地址',
  'bind.bj': '编辑',
  'bind.sc': '删除',
  'bind.qrsc': '确定要删除该地址?',
  'bind.qbdz': '钱包地址',
  'bind.tjsj': '添加时间',
  'bind.cz': '操作',
  'withdrawal.address.choice': '请选择提现地址!',

  'order.sxsj': '生效时间',
  'order.ljsj': '立即生效',
  'order.dssj': '定时生效',

  'store.st.zt': '店铺状态',
  'store.st.zc': '正常',
  'store.st.zc.desc': '您的商铺一切正常，请继续保持',
  'store.st.yc': '异常',
  'store.st.yc.desc': '您的商铺存在部分的订单异常或收到客诉',
  'store.st.dj': '冻结',
  'store.st.dj.desc': '您的商铺存在一定的经营风险造成资金冻结',
  'store.st.jy': '禁用',
  'store.st.jy.desc': '您的商铺违返了平台相关协议与规则已禁用',

  'task.sy': '首页',
  'task.rwlbo': '任务列表',
  'task.rwxqo': '任务详情',
  'task.ljcy': '立即参与',
  'task.gdrw': '更多任务',
  'task.rwlb': '抢单任务列表',
  'task.qbrw': '全部任务',
  'task.wcyd': '我参与的',
  'task.qdz': '抢单中',
  'task.ywc': '已完成',
  'task.djy': '待交易',
  'task.ddje': '订单金额',
  'task.cyrs': '参与人数',
  'task.ewsy': '额外收益',
  'task.jzsj': '截止时间',
  'task.ckxq': '查看详情',
  'task.rwxq': '任务详情',
  'task.cylb': '参与列表',
  'task.qdcyrw': '确定要参与此订单任务吗?',
  'task.sd': '是的',
  'task.bue': '不',

  'task.wdsy': '收益',
  'task.wdfh': '分红',
  'task.wdqd': '抢单',
  'task.dd.leixin': '订单类型',
  'task.dd.qdrw': '抢单任务',
  'task.dd.ptdd': '普通订单',

  'flow.lltg': '流量推广',
  'flow.gmll': '购买流量套餐',
  'flow.dqtc': '当前套餐',
  'flow.tc1': '套餐',
  'flow.sy2': '剩余',
  'flow.tian3': '天',
  'flow.jlzs': '记录总数',
  'flow.lltc': '流量套餐',
  'flow.ll': '流量',
  'flow.gmsj': '购买时间',
  'flow.tcjg': '套餐价格',
  'flow.sjzf': '实际支付',
  'flow.tcsm': '套餐说明',
  'flow.tcsm.sm': '购买此套餐，将增加店铺的曝光率，获得更大的客户访问流量。以此来获得更多订单，提高店铺收益。',

  'flow.tcmc': '套餐名称',
  'flow.gmsc': '购买时长',
  'flow.zje': '总金额',
  'flow.jzsj': '截止时间',
  'flow.gmrq': '购买日期',
  'flow.tian1': '天',
  'flow.zhou1': '周',
  'flow.yue1': '月',

  'share.dj': '等级',
  'share.hz': '划转',
  'share.hzd': '划转到',
  'share.qbye': '钱包余额',
  'share.tgje': '推广金额',
  'share.hzje': '划转金额',
  'share.qsrhzje': '请输入划转金额',

  '_inv._title': '邀请说明',
  '_inv._t1': '1.邀请与被邀请关系',
  '_inv._desc1': 'A邀请B、B邀请C、C邀请D。 最高4级,若D再邀请E, 则E与A无关。',
  '_inv._t2': '2.邀请人获得奖励',
  '_inv._desc2': 'A获得B订单完成总金额的4%，A获得C订单完成的总金额2%，A获得D订单完成的总金额1%',

  '_index._gm': '购买',
  '_index._sm': '说明：增加店铺曝光率，吸引客流量',

  '_n.dlyzc': '登录与注册',
  '_n.hy4': '还有4个',
  '_n.ckgd': '查看更多',
  '_n._cxhzk': '促销和折扣',
  '_n._cwyp': '宠物用品',
  '_n._tjsp': '特价商品',
  '_n._rmfl': '热门分类',
  '_n._tjsp2': '推荐商品',
  '_n._rxsp': '推荐商品',
  '_n._rmsp': '热门商品',
  '_n._gdsp': '更多商品',
  '_n._yzsj': '优质商家',

  '_n._zxsc': '在线商城',
  '_n._fkyys': '付款与运输',
  '_n._xsgz': '销售规则',
  '_n._hhjth': '换货及退货',
  '_n._lxwm': '联系我们',
  '_n._sczc': '商城政策',
  '_n._tkytj': '条款与条件',
  '_n._tuzc': '退货政策',
  '_n._zczc': '支持政策',
  '_n._yszc': '隐私政策',
  '_n._scjj': '商城简介',
  '_n._ljgm': '立即购买',

  '_n2._rxsp': '热销商品',
  '_n2._pl': '评论',
  '_n2._sjxx': '商家信息',
  '_n2._rhsh': '如何收货',
  '_n2._xsxd': '线上下单',
  '_n2._jscldd': '专人客服',
  '_n2._mfps': '免费配送',
  '_n2._2xsfh': '闪电发货',
  '_n2._1d5tdd': '1-5天内到达',
  '_n2._tkzd': '特快专递',
  '_n2._ckfh': '从仓库发货',
  '_n2._zcps': '附近仓库',
  '_n2._jsd': '收货点',
  '_n2._qsjps': '全世界配送',
  '_n2._thtj': '退货条件',
  '_n2._thtjsm': '自购买之日起 14 天内，您可以换货或退货',
  '_n2._gycp': '关于产品',
  '_n2._yc': '隐藏',
  '_n2._tiao': '条',
  '_n2._sypl': '所有评论',
  '_n2._lxfk': '留下反馈',
  '_n2._xzspgg': '请选择商品规格',
  '_n2._qxdl': '请先登录',
  '_n2._rnsj': '如你所见',
  '_n2._rx': '热销',

  '_n3._qrdd': '确认订单',
  '_n3._njsp': '{num}件商品',
  '_n3._ddzf': '订单支付',
  '_n3._wydlsp': '欢迎登录店铺',

  '_n3._sjwfgm': '商家不能购买商品',
  '_n3._sjbnlt': '商家不能聊天',
  '_chat._hc': '撤回',

  '_zc._sryxdz': '请输入邮箱',
  '_zc._yxgscw': '邮箱格式错误',
  '_zc._qsrmm': '请输入密码',
  '_zc._lcmmbxd': '两次密码不一致',
  '_zc._hdyzwtg': '请通过滑动条验证',
  '_zc._qsryqm': '请输入邀请码',
  '_zc._yhzh': '注册用户账号',
  '_zc._qzc': '去注册',
  '_zc._srdpm': '请输入店铺名',

  '_zc._gszz': '公司资质',
  '_zc._ptzz': '平台相关资格',

  '_dd._tijd': '提交订单中',
  '_dd._xzdz': '请选择收货地址',
  '_dd._zfz': '支付中',

  '_wd._zf': '支付',
  '_wd._cz': '充值',
  '_wd._txsq': '提现申请',
  '_wd._ddsy': '订单收益',
  '_wd._cgzf': '采购',
  '_wd._txbh': '驳回',
  '_wd._txtg': '通过',
  '_wd._ddtk': '订单退款',
  '_wd._yehz': '划转',
  '_wd._gmll': '购买流量',
  '_wd._zjkc': '租金扣除',
  '_wd._fk': '罚款',
  '_wd._ns': '纳税',
  '_wd._bzj': '保证金',

  '_xxtz._zntz': '站内通知',
  '_xxtz._fh': '返回',
  '_xxtz._jzgd': '加载更多',
  '_xxtz._mysj': '没有了',

  '_sjsy._jrdd': '今日订单',
  '_sjsy._rxse': '日销售额',

  '_sjsy._xh': '序号',

  '_wd._nbjy': '内部划转',
  '_wd._qsruid': '请输入收款人UID',
  '_wd._je': '金额',
  '_wd._qsrhzje': '请输入划转金额',
  '_wd._skr': '收款人',
  '_wd._nbhzsm': '向平台用户内部划转,请仔细核对收款人信息',

  '_st._spxlphb': '商品销量排行榜',
  '_st._czsp': '重置搜索',

  '_st._pljrgwc': '全部加入',

  '_sj._yxz': '已选中',
  '_sj._hjzje': '合计总金额',
  '_sj._zdcr': '自动存入',

  '_wd._ddfh': '订单分红',

  '_dd._csqx': '订单取消',

  '_tx._yhktx': '银行提现',
  '_tx._sryhmc': '请输入银行名称',
  '_tx._srskrmc': '请输入收款人名称',
  '_tx._srskzh': '请输入收款账号',
  '_tx._sryhluh': '请输入银行路由号',
  '_tx._sryhdm': '请输入银行代码',
  '_tx._sryhdz': '请输入银行地址',
  '_tx._txje': '提现金额',
  '_tx._srtxje': '请输入提现金额',
  '_tx._txkc': '提现手续费{_sxf}%',

  '_yye._yyye': '月销售额',
  '_yye._ysy': '月收益',

  '_sj._hjzsy': '合计总收益',

  '_yhdl._dl': '登录',
  '_yhdl._zc': '创建账户',

  '_zdy._24zrkf': '我们24/7随时为您提供协助',
  '_zdy._zhsz': '账户设置',
  '_zdy._mfps': '在有限的时间内，您可以在选定区域内享受数千种商品的免费 2 个工作天送货服务。 ',
  '_zdy._gmsp': '选购新品',
  '_zdy._czyh': '超值优惠，尽享您喜爱的商品',
  '_zdy._xsgw': '限时购物！ 在美国东部时间 5 月 12 日中午之前订购免运费。 专人专车送上门！ ',
  '_zdy._ssgjc': '输入关键词搜索',

  '_zdy._rmjx': '您的首选',

  '_dd._plcz': '批量操作',
  '_dd._plcg': '批量采购',
  '_dd._qgxcgd': '请勾选需要采购的订单',
  '_dd._yxddbcg': '已选订单不需要采购',
  '_dd._qdyplcgm': '确定批量采购已选择的订单吗？,数量:{n}',
  '_dd._qdcg': '确定采购',
  '_dd._qx': '取消',
  '_dd._plcgwc': '批量采购完成，成功:{s}/{t}',
  '_dd._zzcgz': '采购中',

  '_st._czhk': '充值还款',
  '_st._dkje': '贷款金额',
  '_st._dkjl': '贷款记录',
  '_st._hkfs': '还款方式',
  '_st._hkje': '还款金额',
  '_st._jrong': '商城金融',
  '_st._ljsq': '立即申请',
  '_st._qrshkje': '请输入还款金额',
  '_st._qsrdkje': '请输入贷款金额',
  '_st._shz': '审核中',
  '_st._sqdklx': '贷款',
  '_st._sqhk': '申请还款',
  '_st._sqhklx': '还款',
  '_st._sqjk': '申请贷款',
  '_st._sqlx': '类型',
  '_st._ybh': '拒绝',
  '_st._yjk': '已借款',
  '_st._ytg': '通过',
  '_st._zzhk': '待还款',

  '_st._dqed': '贷款额度',
  '_st._ccdked': '不能超出贷款额度',
  '_st._tjsqz': '提交申请中',
  '_st._dklx': '利息',
  '_st._dkll': '贷款利率:{n}%',
  '_kdts._ts': '已营业{t}天',
  '_jltg._tgje': '广告投入',

  '_st._yqk': '欠款',
  '_st._qb': '全部',

  '_spxx._spbm': '商品编码',

  '_scrz._yyzz': '营业执照',

  '_sjhb._sjbt': '优惠券',
  '_sjhb._kfxd': '优惠券列表',
  '_sjhb._fxlb': '分享列表',
  '_sjdhb._hbje': '面额',
  '_sjdhb._fxzs': '分享总数',
  '_sjdhb._fxcz': '操作',
  '_sjdhb._fxyhj': '分享',
  '_sjdhb._gqsj': '过期时间',
  '_sjdhb._lqsj': '领取时间',
  '_sjdhb._lqbm': '编码',
  '_sjdhb._wlq': '未领取',
  '_sjdhb._wsy': '未使用',
  '_sjdhb._ysy': '已使用',
  '_sjdhb._ylq': '已领取',
  '_sjdhb._ygq': '已过期',
  '_sjdhb._fuzi': '复制',
  '_sjdhb._lqyhj': '优惠券',
  '_sjdhb._lqlqyhj': '领取优惠券',
  '_sjdhb._yhjbm': '优惠券编码',
  '_sjdhb._ljlq': '立即领取'
}
