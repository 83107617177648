<template>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getRedbagRecord" style="width: 100%">
    <template #bodyCell="{ column, record }">
      <!-- <template v-if="record && column.title === 'Name'">
        <div style="display:flex;align-items: center;">
          <img :src="record.img" width="40" style="margin-right:5px" />
          <div style="font-size:13px">{{record.name}}</div>
        </div>
      </template>
      -->
      <template v-if="record && column.key === 'total'">
        <div>{{ record.sharebag?.total || 0 }} / {{ record.total }}</div>
      </template>
      <template v-if="record && column.key === 'caozuo'">
        <div>
          <a-button type="dashed" @click="onMakeRedbag(record)">{{ $t('_sjdhb._fxyhj') }}</a-button>
        </div>
      </template>
      <!-- <template v-if="record && column.key === 'is_use'">
        <a-tag :color="statusType(record.is_use).color">{{ statusType(record.is_use).text }}</a-tag>
      </template> -->
    </template>
  </a-table>
</template>

<script>
export default {
  name: 'ReceiveList',
  data() {
    return {
      //存币记录表格
      columns: [
        {
          title: this.$t('_sjsy._xh'),
          customRender: (record) => {
            if (!this.loading) {
              return (this.pagination.current - 1) * this.pagination.pageSize + record.index + 1
            }
          },
          width: '10%'
        },
        {
          title: this.$t('_sjdhb._hbje'),
          customRender: (item) => {
            return '$ ' + item.record.amount
          },
          width: '40%'
        },
        {
          title: this.$t('_sjdhb._fxzs'),
          key: 'total',
          width: '20%'
        },
        {
          title: this.$t('_sjdhb._fxcz'),
          key: 'caozuo',
          width: '10%'
        }
      ],
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10, is_use: 1 },
      dataSource: [],
      queryParam: {}
    }
  },
  created() {
    this.getRedbagRecord(this.pagination)
  },
  methods: {
    refresh() {
      this.getRedbagRecord({ total: 0, current: 1, pageSize: 10, is_use: 1 })
    },
    statusType(status) {
      return this.status[status]
    },
    getRedbagRecord(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({ is_use: parameter.is_use }, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.redbag.getRedbagList(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    },
    onMakeRedbag(item) {
      this.$message.loading({ content: 'loading...', key: 'msg' })

      let that = this
      this.$api.redbag.makeRedbag({ code: item.code }).then((res) => {
        if (res.code == 1) {
          this.$message.error({ content: res.msg, key: 'msg' })
          return
        }
        this.$message.success({ content: res.msg, key: 'msg' })

        this.onCopy(res.data.code)
        that.refresh()
        that.$emit('refresh')
      })
    },
    /**
     * 复制地址
     */
    onCopy(token) {
      console.log(token)
      // copyData想要复制的内容
      this.$copyText(token)
        .then(() => {
          this.$message.success('copy success! ' + token)
        })
        .catch(() => {
          this.$message.error('copy fail')
        })
    }
  }
}
</script>

<style></style>
