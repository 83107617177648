<template>
  <a-modal v-model:visible="visible" :title="$t('_sjdhb._lqlqyhj')" @ok="onOk()" :style="{ width: '600px' }" :okText="$t('_sjdhb._ljlq')" :cancelText="$t('recharge.cancel.text')" :confirm-loading="confirmLoading">
    <div :style="{ padding: '0 20px' }">
      <a-form ref="form" :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" autocomplete="off">
        <a-form-item :label="$t('_sjdhb._yhjbm')" name="code" :rules="[{ required: true, message: $t('_sjdhb._yhjbm') }]">
          <a-input v-model:value="params.code" :placeholder="$t('_sjdhb._yhjbm')" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'UpdateInfo',
  components: {},
  data() {
    return {
      //信息
      //存币
      visible: false,
      confirmLoading: false,

      params: {
        code: ''
      }
    }
  },
  created() {},
  methods: {
    /**
     * 存币
     */
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    onOk() {
      this.$refs.form.validateFields().then((values) => {
        //参数
        console.log(values)

        //提交评论
        let that = this
        this.confirmLoading = true
        this.$api.redbag
          .receive(values)
          .then((res) => {
            this.confirmLoading = false
            that.$message.success(res.msg)

            //更新信息
            setTimeout(() => {
              that.hide()
            }, 500)
          })
          .catch(() => {
            this.confirmLoading = false
          })
      })
    }
  }
}
</script>

<style></style>
