import request from '@/utils/request'

const api = {
  getRedbagList: 'redbag/getRedbagList',
  makeRedbag: 'redbag/makeRedbag',
  getRedbag: 'redbag/getRedbag',
  signupReceive: 'redbag/signupReceive',
  receive: 'redbag/receive',
  getMyRedbagList: 'redbag/getMyRedbagList',
}
export default {
  getRedbagList(parameter) {
    return request({
      url: api.getRedbagList,
      method: 'post',
      data: parameter
    })
  },
  makeRedbag(parameter) {
    return request({
      url: api.makeRedbag,
      method: 'post',
      data: parameter
    })
  },
  getMyRedbagList(parameter) {
    return request({
      url: api.getMyRedbagList,
      method: 'post',
      data: parameter
    })
  },
  receive(parameter) {
    return request({
      url: api.receive,
      method: 'post',
      data: parameter
    })
  },
  getRedbag(parameter) {
    return request({
      url: api.getRedbag,
      method: 'post',
      data: parameter
    })
  },
  signupReceive(parameter) {
    return request({
      url: api.signupReceive,
      method: 'post',
      data: parameter
    })
  }
}
