<template>
  <section id="layer-1" class="vYF33 nrGtK" style="left: 0px; top: 0px; position: relative; z-index: 5">
    <a class="BK8bF OfW5X" href="#main-content-start">Skip navigation</a>
    <section class="TqINp">
      <div class="BwqBX yEUew undefined">
        <!-- 顶部广告 -->
        <!-- <div class="iurNK undefined PbTRv YU8pH" style="background-color: rgb(0, 0, 0); text-align: center">
          <div class="U_Vu3">
            <div class="UYr2M fWRC1">
              <div class="Kv5KD">
                <span class="TFM6u" color="light">
                  <p>
                    <strong>{{ $t('_zdy._xsgw') }}</strong>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div> -->
        <div id="global-header-desktop" class="U_Vu3">
          <div class="UYr2M fWRC1 kRP1D">
            <router-link :to="{ name: 'index' }" class="hxAfD cQ7wR IdMRc RTmqg">
              <figure class="rq8ns"></figure>
            </router-link>
            <section id="keyword-search-desktop" class="VZ3iE MSkvK">
              <div class="DZQ3D" id="controls-keyword-search-popover" aria-controls="keyword-search-popover" style="position: relative">
                <div id="keyword-search-form" class="Q0pMB" role="search" aria-label="Search">
                  <div class="bAwLN">
                    <div class="y4A01" id="search-icon">
                      <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconSearch" class="y4A01">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C12.125 19 14.078 18.2635 15.6177 17.0319L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L17.0319 15.6177C18.2635 14.078 19 12.125 19 10C19 5.02944 14.9706 1 10 1ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10Z" fill="#191A1B"></path>
                      </svg>
                    </div>
                    <input class="RTf5v" type="search" autocomplete="off" :placeholder="$t('_zdy._ssgjc')" maxlength="140" v-model="searchVal" @input="onSearchInput" />
                    <button class="TBFPu DVqzq" @click="onClearSearch">
                      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconClose" class="GgTxN">
                        <path d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L12 10.5858L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.4142 12L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12 13.4142L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.5858 12L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="#191A1B"></path>
                      </svg>
                    </button>
                  </div>
                  <!-- 搜索结果 -->
                  <div class="YVqE3" v-if="searchShop.length > 0">
                    <div class="GX7lR SBnes" id="keyword-search-popover" role="tooltip" style="padding-top: 0px; position: absolute; width: 100%; z-index: 10">
                      <div class="TlLMR">
                        <div class="ckrPL DeiTU">
                          <div class="hTauN">
                            <div class="mZmnx">
                              <section class="ljlCX yZwrV">
                                <!-- <header class="XdSNQ">
                                  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconTrendUp">
                                    <g clip-path="url(#clip0_916_6810)">
                                      <path d="M18 4C17.4477 4 17 4.44772 17 5C17 5.55228 17.4477 6 18 6H20.5858L13 13.5858L9.70711 10.2929C9.51957 10.1054 9.26522 10 9 10C8.73478 10 8.48043 10.1054 8.29289 10.2929L0.292893 18.2929C-0.0976311 18.6834 -0.0976311 19.3166 0.292893 19.7071C0.683418 20.0976 1.31658 20.0976 1.70711 19.7071L9 12.4142L12.2929 15.7071C12.6834 16.0976 13.3166 16.0976 13.7071 15.7071L22 7.41421V10C22 10.5523 22.4477 11 23 11C23.5523 11 24 10.5523 24 10V5C24 4.44772 23.5523 4 23 4H18Z" fill="#191A1B"></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_916_6810"><rect width="24" height="24" fill="white"></rect></clipPath>
                                    </defs>
                                  </svg>
                                  <h2 class="s1Xvu dls-boa17d">Store Keyword</h2>
                                </header> -->
                                <ul class="oajAI lpWlW" id="search-suggestions">
                                  <li class="t3HPl" v-for="(item, i) in searchShop" :key="i" @click="onSearchShop(item)">
                                    <span class="LbkFK ajJZu">
                                      <b>{{ item.name }}({{ item.sid }})</b>
                                    </span>
                                  </li>
                                </ul>
                              </section>
                              <!-- <section class="ljlCX UDiT3" >
                                <header class="XdSNQ">
                                  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconCategoryOutlined">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 4.5C13 3.11929 14.1193 2 15.5 2H19.5C20.8807 2 22 3.11929 22 4.5V8.5C22 9.88071 20.8807 11 19.5 11H15.5C14.1193 11 13 9.88071 13 8.5V4.5ZM15.5 4C15.2239 4 15 4.22386 15 4.5V8.5C15 8.77614 15.2239 9 15.5 9H19.5C19.7761 9 20 8.77614 20 8.5V4.5C20 4.22386 19.7761 4 19.5 4H15.5Z" fill="#191A1B"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 13C3.11929 13 2 14.1193 2 15.5V19.5C2 20.8807 3.11929 22 4.5 22H8.5C9.88071 22 11 20.8807 11 19.5V15.5C11 14.1193 9.88071 13 8.5 13H4.5ZM4 15.5C4 15.2239 4.22386 15 4.5 15H8.5C8.77614 15 9 15.2239 9 15.5V19.5C9 19.7761 8.77614 20 8.5 20H4.5C4.22386 20 4 19.7761 4 19.5V15.5Z" fill="#191A1B"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 13C14.1193 13 13 14.1193 13 15.5V19.5C13 20.8807 14.1193 22 15.5 22H19.5C20.8807 22 22 20.8807 22 19.5V15.5C22 14.1193 20.8807 13 19.5 13H15.5ZM15 15.5C15 15.2239 15.2239 15 15.5 15H19.5C19.7761 15 20 15.2239 20 15.5V19.5C20 19.7761 19.7761 20 19.5 20H15.5C15.2239 20 15 19.7761 15 19.5V15.5Z" fill="#191A1B"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 2C3.11929 2 2 3.11929 2 4.5V8.5C2 9.88071 3.11929 11 4.5 11H8.5C9.88071 11 11 9.88071 11 8.5V4.5C11 3.11929 9.88071 2 8.5 2H4.5ZM4 4.5C4 4.22386 4.22386 4 4.5 4H8.5C8.77614 4 9 4.22386 9 4.5V8.5C9 8.77614 8.77614 9 8.5 9H4.5C4.22386 9 4 8.77614 4 8.5V4.5Z" fill="#191A1B"></path>
                                  </svg>
                                  <h2 class="s1Xvu dls-boa17d">Categories</h2>
                                </header>
                                <ul class="hgtcT Rhcg5" id="keyword-search-category-suggestions">
                                  <li id="keyword-search-category-0" class="JIZ1z">
                                    <span class="LbkFK p8gCU"><b>sizes </b>2<b>t-6x</b></span
                                    ><span>&nbsp;in&nbsp;</span><span class="KQenx">Kids / Girls' Clothing</span>
                                  </li>
                                  <li id="keyword-search-category-1" class="JIZ1z">
                                    <span class="LbkFK p8gCU"><b>sizes </b>2<b>t-7</b></span
                                    ><span>&nbsp;in&nbsp;</span><span class="KQenx">Kids / Boys' Clothing</span>
                                  </li>
                                </ul>
                              </section> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span id="keyword-search-popover" class="jTZe7 OfW5X"></span>
            </section>

            <div class="sulP1">
              <!-- 购物车 -->
              <span class="f8sra" v-if="sp_info.type == 1">
                <div class="_15Ld">
                  <span id="shopping-bag-popover" class="jTZe7 OfW5X"></span>
                  <a @click="onGoCart()" class="_VrDR shopping-bag-links" href="javascript:" style="position: relative">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconShoppingBagNumberOutlined" class="lvL2s" role="img" title="Shopping Bag"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C9.79086 0 8 1.79086 8 4V6H4C2.89543 6 2 6.89543 2 8V20C2 22.2091 3.79086 24 6 24H18C20.2091 24 22 22.2091 22 20V8C22 6.89543 21.1046 6 20 6H16V4C16 1.79086 14.2091 0 12 0ZM14 8V9C14 9.55228 14.4477 10 15 10C15.5523 10 16 9.55228 16 9V8H20V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V8H8V9C8 9.55228 8.44772 10 9 10C9.55228 10 10 9.55228 10 9V8H14ZM14 6V4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4V6H14Z" fill="#191A1B"></path></svg>
                    <span class="X4gwf">{{ cart }}</span>
                  </a>
                </div>
              </span>

              <!-- 登录 -->
              <div class="ADSl">
                <div class="tSWA0 TsMenu" href="javascript:" id="controls-account-links" aria-controls="account-links" style="position: relative">
                  <div v-if="!isLogin">
                    <span class="FeZND">{{ $t('_yhdl._dl') }}</span>
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconCaretDown" class="xTDVg">
                      <path d="M12.5303 15.4697L17.1465 10.8536C17.4614 10.5386 17.2384 10 16.7929 10L7.20712 10C6.76167 10 6.53858 10.5386 6.85356 10.8536L11.4697 15.4697C11.7626 15.7626 12.2374 15.7626 12.5303 15.4697Z" fill="#191A1B"></path>
                    </svg>
                  </div>
                  <div class="Re Resdf" v-if="isLogin">
                    <div>
                      <img :src="sp_info.avatar" alt="" style="width: 30px; height: 30px; border-radius: 50%" />
                    </div>
                    <div class="kB">
                      <a class="lHtRS cQ7wR IdMRc RTmqg ng-mob">{{ sp_info.nickname }}</a>
                    </div>
                  </div>

                  <!-- 下拉框 -->
                  <aside class="GX7lR -SBnes GsMenu" style="top: 100%; left: 0px; padding-top: 16px; z-index: 1; position: absolute">
                    <div class="TlLMR">
                      <div class="ckrPL">
                        <section class="yFHT9">
                          <a class="QjDI0 I_0O9 btBnB E9j5R" @click="onSignin()" v-if="!isLogin">
                            <span class="WTrSQ">{{ $t('_yhdl._dl') }} | {{ $t('_yhdl._zc') }}</span>
                          </a>
                          <div class="bodse" v-if="isLogin">
                            <div>
                              <img :src="sp_info.avatar" alt="" style="width: 45px; height: 45px; border-radius: 50%" />
                            </div>
                            <div>
                              <h2 class="Qd1JD dls-boa17d">
                                <a class="lcFyR tuUGG RTmqg">{{ sp_info.nickname }}</a>
                              </h2>
                              <div>UID:{{ sp_info.sid }}</div>
                            </div>
                          </div>
                          <div class="bovGa" style="margin-top: 20px">
                            <h2 class="Qd1JD dls-boa17d">
                              <a class="lcFyR tuUGG RTmqg">{{ $t('_zdy._zhsz') }}</a>
                            </h2>
                          </div>
                          <!-- 用户 -->
                          <ul v-if="sp_info.type == 1">
                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="piRuM">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1877 1.26662C11.7049 1.03678 12.2951 1.03678 12.8123 1.26662L22.4061 5.53055C22.7673 5.69106 23 6.04918 23 6.44437V16.9056C23 17.696 22.5345 18.4122 21.8123 18.7332L12.8123 22.7332C12.2951 22.9631 11.7049 22.9631 11.1877 22.7332L2.18772 18.7332C1.46547 18.4122 1 17.696 1 16.9056V6.44437C1 6.04918 1.23273 5.69106 1.59386 5.53055L11.1877 1.26662ZM3 16.9056L11 20.4612V11.6373L3 7.91115V16.9056ZM4.52642 6.41583L6.97136 7.55468L14.3272 4.12854L12 3.09424L4.52642 6.41583ZM16.7511 5.20584L9.33984 8.65781L12 9.89677L19.4735 6.4158L16.7511 5.20584ZM21 7.91111L13 11.6373V20.4612L21 16.9056V7.91111Z" fill="#191A1B"></path>
                              </svg>
                              <router-link :to="{ name: 'overview' }" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('member.overview') }}</router-link>
                            </li>
                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconHeartOutlined" class="piRuM">
                                <g clip-path="url(#clip0_1864_131)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.000244141 8.75C0.000244141 5.02208 3.02232 2 6.75024 2C8.87178 2 10.7634 2.9787 12.0002 4.5073C13.2371 2.9787 15.1287 2 17.2502 2C20.9782 2 24.0002 5.02208 24.0002 8.75C24.0002 12.7009 21.1472 16.1603 18.4721 18.5682C15.7458 21.0223 12.9324 22.6252 12.531 22.8492C12.3988 22.923 12.218 22.9884 12.0002 22.9884C11.7825 22.9884 11.6017 22.923 11.4695 22.8492C11.0681 22.6252 8.25466 21.0223 5.52836 18.5682C2.85324 16.1603 0.000244141 12.7009 0.000244141 8.75ZM6.75024 4C4.12689 4 2.00024 6.12665 2.00024 8.75C2.00024 11.7828 4.25066 14.7272 6.8664 17.0817C8.93547 18.9442 11.0698 20.2922 12.0002 20.8457C12.9307 20.2922 15.065 18.9442 17.1341 17.0817C19.7498 14.7272 22.0002 11.7828 22.0002 8.75C22.0002 6.12665 19.8736 4 17.2502 4C15.3196 4 13.6558 5.15193 12.9128 6.81019C12.7517 7.16983 12.3943 7.4013 12.0002 7.4013C11.6061 7.4013 11.2488 7.16983 11.0877 6.81019C10.3447 5.15193 8.6809 4 6.75024 4Z"
                                    fill="#191A1B"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1864_131"><rect width="24" height="24" fill="white"></rect></clipPath>
                                </defs>
                              </svg>
                              <router-link :to="{ name: 'orderAll' }" class="KCBwR lcFyR tuUGG RTmqg"
                                >{{ $t('member.orders') }}<span style="color: red">({{ msgCenter.order }})</span></router-link
                              >
                            </li>
                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconCreditCardBackOutlined" class="piRuM">
                                <path d="M18 12C17.4477 12 17 12.4477 17 13C17 13.5523 17.4477 14 18 14H19C19.5523 14 20 13.5523 20 13C20 12.4477 19.5523 12 19 12H18Z" fill="#191A1B"></path>
                                <path d="M13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H14C13.4477 14 13 13.5523 13 13Z" fill="#191A1B"></path>
                                <path d="M10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44771 14 10 14H11C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12H10Z" fill="#191A1B"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6C0 4.34315 1.34315 3 3 3H21C22.6569 3 24 4.34315 24 6V18C24 19.6569 22.6569 21 21 21H3C1.34314 21 0 19.6569 0 18V6ZM22 6V8H2V6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44771 22 6ZM22 10H2V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V10Z" fill="#191A1B"></path>
                              </svg>
                              <router-link :to="{ name: 'payment' }" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('member.payment') }}</router-link>
                            </li>
                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconFreeShippingOutlined" class="piRuM">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2 3C0.895431 3 0 3.89543 0 5V15C0 15.771 0.436269 16.4401 1.07541 16.7739C1.02599 17.0082 1 17.251 1 17.5C1 19.433 2.567 21 4.5 21C6.433 21 8 19.433 8 17.5C8 17.3302 7.98791 17.1633 7.96456 17H14.0354C14.0121 17.1633 14 17.3302 14 17.5C14 19.433 15.567 21 17.5 21C19.433 21 21 19.433 21 17.5C21 17.3302 20.9879 17.1633 20.9646 17H23C23.5523 17 24 16.5523 24 16V13C24 12.8448 23.9639 12.6916 23.8944 12.5528L21.8944 8.55279C21.725 8.214 21.3788 8 21 8H19V5C19 3.89543 18.1046 3 17 3H2ZM19.9495 15H22V13.2361L20.382 10H19V14.3368C19.353 14.5045 19.6736 14.7296 19.9495 15ZM17 14.0354V5L2 5V15.0505C2.63526 14.4022 3.52066 14 4.5 14C5.45365 14 6.31822 14.3814 6.94949 15H15V15.0505C15.5251 14.5146 16.2212 14.1468 17 14.0354ZM4.5 16C3.67157 16 3 16.6716 3 17.5C3 18.3284 3.67157 19 4.5 19C5.32843 19 6 18.3284 6 17.5C6 16.6716 5.32843 16 4.5 16ZM16 17.5C16 16.6716 16.6716 16 17.5 16C18.3284 16 19 16.6716 19 17.5C19 18.3284 18.3284 19 17.5 19C16.6716 19 16 18.3284 16 17.5Z"
                                  fill="#191A1B"
                                ></path>
                              </svg>
                              <router-link :to="{ name: 'address' }" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('member.address') }}</router-link>
                            </li>
                            <li>
                              <comment-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                              <router-link :to="{ name: 'message' }" class="KCBwR lcFyR tuUGG RTmqg"
                                >{{ $t('member.message.center') }}<span style="color: red">({{ msgCenter.msg }})</span></router-link
                              >
                            </li>
                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconHeartOutlined" class="piRuM">
                                <g clip-path="url(#clip0_1864_131)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.000244141 8.75C0.000244141 5.02208 3.02232 2 6.75024 2C8.87178 2 10.7634 2.9787 12.0002 4.5073C13.2371 2.9787 15.1287 2 17.2502 2C20.9782 2 24.0002 5.02208 24.0002 8.75C24.0002 12.7009 21.1472 16.1603 18.4721 18.5682C15.7458 21.0223 12.9324 22.6252 12.531 22.8492C12.3988 22.923 12.218 22.9884 12.0002 22.9884C11.7825 22.9884 11.6017 22.923 11.4695 22.8492C11.0681 22.6252 8.25466 21.0223 5.52836 18.5682C2.85324 16.1603 0.000244141 12.7009 0.000244141 8.75ZM6.75024 4C4.12689 4 2.00024 6.12665 2.00024 8.75C2.00024 11.7828 4.25066 14.7272 6.8664 17.0817C8.93547 18.9442 11.0698 20.2922 12.0002 20.8457C12.9307 20.2922 15.065 18.9442 17.1341 17.0817C19.7498 14.7272 22.0002 11.7828 22.0002 8.75C22.0002 6.12665 19.8736 4 17.2502 4C15.3196 4 13.6558 5.15193 12.9128 6.81019C12.7517 7.16983 12.3943 7.4013 12.0002 7.4013C11.6061 7.4013 11.2488 7.16983 11.0877 6.81019C10.3447 5.15193 8.6809 4 6.75024 4Z"
                                    fill="#191A1B"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1864_131"><rect width="24" height="24" fill="white"></rect></clipPath>
                                </defs>
                              </svg>
                              <router-link :to="{ name: 'redbag' }" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('_sjhb._sjbt') }}</router-link>
                            </li>
                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconNewWindow" class="piRuM">
                                <path d="M14 2C13.4477 2 13 2.44772 13 3C13 3.55228 13.4477 4 14 4H19.0658L7.29289 15.7729C6.90237 16.1634 6.90237 16.7966 7.29289 17.1871C7.68342 17.5776 8.31658 17.5776 8.70711 17.1871L20 5.89419V10C20 10.5523 20.4477 11 21 11C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14Z" fill="#191A1B"></path>
                                <path d="M5 7C4.44772 7 4 7.44772 4 8V19C4 19.5523 4.44772 20 5 20H16C16.5523 20 17 19.5523 17 19V13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13V19C19 20.6569 17.6569 22 16 22H5C3.34315 22 2 20.6569 2 19V8C2 6.34315 3.34315 5 5 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H5Z" fill="#191A1B"></path>
                              </svg>

                              <a href="javascript:" @click="onSignout()" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('signup.sign.out') }}</a>
                            </li>
                          </ul>
                          <!-- 商家 -->
                          <ul v-if="sp_info.type == 2">
                            <li>
                              <dashboard-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                              <router-link :to="{ name: 'storeDashboard' }" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('store.dashboard') }}</router-link>
                            </li>
                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconHeartOutlined" class="piRuM">
                                <g clip-path="url(#clip0_1864_131)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.000244141 8.75C0.000244141 5.02208 3.02232 2 6.75024 2C8.87178 2 10.7634 2.9787 12.0002 4.5073C13.2371 2.9787 15.1287 2 17.2502 2C20.9782 2 24.0002 5.02208 24.0002 8.75C24.0002 12.7009 21.1472 16.1603 18.4721 18.5682C15.7458 21.0223 12.9324 22.6252 12.531 22.8492C12.3988 22.923 12.218 22.9884 12.0002 22.9884C11.7825 22.9884 11.6017 22.923 11.4695 22.8492C11.0681 22.6252 8.25466 21.0223 5.52836 18.5682C2.85324 16.1603 0.000244141 12.7009 0.000244141 8.75ZM6.75024 4C4.12689 4 2.00024 6.12665 2.00024 8.75C2.00024 11.7828 4.25066 14.7272 6.8664 17.0817C8.93547 18.9442 11.0698 20.2922 12.0002 20.8457C12.9307 20.2922 15.065 18.9442 17.1341 17.0817C19.7498 14.7272 22.0002 11.7828 22.0002 8.75C22.0002 6.12665 19.8736 4 17.2502 4C15.3196 4 13.6558 5.15193 12.9128 6.81019C12.7517 7.16983 12.3943 7.4013 12.0002 7.4013C11.6061 7.4013 11.2488 7.16983 11.0877 6.81019C10.3447 5.15193 8.6809 4 6.75024 4Z"
                                    fill="#191A1B"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1864_131"><rect width="24" height="24" fill="white"></rect></clipPath>
                                </defs>
                              </svg>
                              <router-link :to="{ name: 'storeProductsList' }" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('store.products.list') }}</router-link>
                            </li>
                            <li>
                              <message-outlined style="font-size: 20px; margin-right: 13px; margin-left: -2px" />
                              <router-link :to="{ name: 'storeProductsReviews' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('reviews.title') }}
                                <span style="color: red">({{ msgCenter.comment }})</span>
                              </router-link>
                            </li>
                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconCreditCardBackOutlined" class="piRuM">
                                <path d="M18 12C17.4477 12 17 12.4477 17 13C17 13.5523 17.4477 14 18 14H19C19.5523 14 20 13.5523 20 13C20 12.4477 19.5523 12 19 12H18Z" fill="#191A1B"></path>
                                <path d="M13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H14C13.4477 14 13 13.5523 13 13Z" fill="#191A1B"></path>
                                <path d="M10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44771 14 10 14H11C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12H10Z" fill="#191A1B"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6C0 4.34315 1.34315 3 3 3H21C22.6569 3 24 4.34315 24 6V18C24 19.6569 22.6569 21 21 21H3C1.34314 21 0 19.6569 0 18V6ZM22 6V8H2V6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44771 22 6ZM22 10H2V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V10Z" fill="#191A1B"></path>
                              </svg>
                              <router-link :to="{ name: 'storeOrderList' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('store.orders') }} <span style="color: red">({{ msgCenter.order }})</span>
                              </router-link>
                            </li>
                            <li>
                              <crown-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                              <router-link :to="{ name: 'storeFinance' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('_st._jrong') }}
                              </router-link>
                            </li>

                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconHeartOutlined" class="piRuM">
                                <g clip-path="url(#clip0_1864_131)">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.000244141 8.75C0.000244141 5.02208 3.02232 2 6.75024 2C8.87178 2 10.7634 2.9787 12.0002 4.5073C13.2371 2.9787 15.1287 2 17.2502 2C20.9782 2 24.0002 5.02208 24.0002 8.75C24.0002 12.7009 21.1472 16.1603 18.4721 18.5682C15.7458 21.0223 12.9324 22.6252 12.531 22.8492C12.3988 22.923 12.218 22.9884 12.0002 22.9884C11.7825 22.9884 11.6017 22.923 11.4695 22.8492C11.0681 22.6252 8.25466 21.0223 5.52836 18.5682C2.85324 16.1603 0.000244141 12.7009 0.000244141 8.75ZM6.75024 4C4.12689 4 2.00024 6.12665 2.00024 8.75C2.00024 11.7828 4.25066 14.7272 6.8664 17.0817C8.93547 18.9442 11.0698 20.2922 12.0002 20.8457C12.9307 20.2922 15.065 18.9442 17.1341 17.0817C19.7498 14.7272 22.0002 11.7828 22.0002 8.75C22.0002 6.12665 19.8736 4 17.2502 4C15.3196 4 13.6558 5.15193 12.9128 6.81019C12.7517 7.16983 12.3943 7.4013 12.0002 7.4013C11.6061 7.4013 11.2488 7.16983 11.0877 6.81019C10.3447 5.15193 8.6809 4 6.75024 4Z"
                                    fill="#191A1B"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1864_131"><rect width="24" height="24" fill="white"></rect></clipPath>
                                </defs>
                              </svg>
                              <router-link :to="{ name: 'storeRedbag' }" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('_sjhb._sjbt') }}</router-link>
                            </li>

                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconFreeShippingOutlined" class="piRuM">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2 3C0.895431 3 0 3.89543 0 5V15C0 15.771 0.436269 16.4401 1.07541 16.7739C1.02599 17.0082 1 17.251 1 17.5C1 19.433 2.567 21 4.5 21C6.433 21 8 19.433 8 17.5C8 17.3302 7.98791 17.1633 7.96456 17H14.0354C14.0121 17.1633 14 17.3302 14 17.5C14 19.433 15.567 21 17.5 21C19.433 21 21 19.433 21 17.5C21 17.3302 20.9879 17.1633 20.9646 17H23C23.5523 17 24 16.5523 24 16V13C24 12.8448 23.9639 12.6916 23.8944 12.5528L21.8944 8.55279C21.725 8.214 21.3788 8 21 8H19V5C19 3.89543 18.1046 3 17 3H2ZM19.9495 15H22V13.2361L20.382 10H19V14.3368C19.353 14.5045 19.6736 14.7296 19.9495 15ZM17 14.0354V5L2 5V15.0505C2.63526 14.4022 3.52066 14 4.5 14C5.45365 14 6.31822 14.3814 6.94949 15H15V15.0505C15.5251 14.5146 16.2212 14.1468 17 14.0354ZM4.5 16C3.67157 16 3 16.6716 3 17.5C3 18.3284 3.67157 19 4.5 19C5.32843 19 6 18.3284 6 17.5C6 16.6716 5.32843 16 4.5 16ZM16 17.5C16 16.6716 16.6716 16 17.5 16C18.3284 16 19 16.6716 19 17.5C19 18.3284 18.3284 19 17.5 19C16.6716 19 16 18.3284 16 17.5Z"
                                  fill="#191A1B"
                                ></path>
                              </svg>
                              <router-link :to="{ name: 'storeWallet' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('store.wallet') }}
                              </router-link>
                            </li>
                            <li>
                              <fire-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                              <router-link :to="{ name: 'storeAdvertiseList' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('advertise.title') }}
                              </router-link>
                            </li>
                            <li>
                              <alert-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                              <router-link :to="{ name: 'storeNotice' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('_xxtz._zntz') }} <span style="color: red">({{ msgCenter.notice }})</span>
                              </router-link>
                            </li>
                            <li>
                              <comment-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                              <router-link :to="{ name: 'storeMessage' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('store.message') }} <span style="color: red">({{ msgCenter.msg }})</span>
                              </router-link>
                            </li>
                            <li v-if="is_moni == 2 || isShare == 2">
                              <share-alt-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                              <router-link :to="{ name: 'storeShare' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('setting.invitation') }}
                              </router-link>
                            </li>
                            <li>
                              <setting-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                              <router-link :to="{ name: 'storeStore' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('store.setting') }}
                              </router-link>
                            </li>
                            <li>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconNewWindow" class="piRuM">
                                <path d="M14 2C13.4477 2 13 2.44772 13 3C13 3.55228 13.4477 4 14 4H19.0658L7.29289 15.7729C6.90237 16.1634 6.90237 16.7966 7.29289 17.1871C7.68342 17.5776 8.31658 17.5776 8.70711 17.1871L20 5.89419V10C20 10.5523 20.4477 11 21 11C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14Z" fill="#191A1B"></path>
                                <path d="M5 7C4.44772 7 4 7.44772 4 8V19C4 19.5523 4.44772 20 5 20H16C16.5523 20 17 19.5523 17 19V13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13V19C19 20.6569 17.6569 22 16 22H5C3.34315 22 2 20.6569 2 19V8C2 6.34315 3.34315 5 5 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H5Z" fill="#191A1B"></path>
                              </svg>
                              <a href="javascript:" @click="onSignout()" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('signup.sign.out') }}</a>
                            </li>
                          </ul>
                          <!-- 24小时客服 -->
                          <!-- <h2 v-if="isLogin" class="fOz0y dls-boa17d">
                            <a class="lcFyR tuUGG RTmqg">{{ $t('_zdy._24zrkf') }}</a>
                          </h2>
                          <ul v-if="isLogin">
                            <li>
                              <CommentOutlined style="margin-right: 13px; font-size: 20px" />
                              <router-link :to="{ name: sp_info.type == 1 ? 'message' : 'storeMessage', query: { customer: 'agent' } }" class="KCBwR lcFyR tuUGG RTmqg"> {{ $t('_n2._jscldd') }} </router-link>
                            </li>
                            <li>
                              <IconWhatsApp style="margin-right: 13px; font-size: 20px" />
                              <a :href="sp_info.whatsapp ? 'https://wa.me/' + sp_info.whatsapp : 'javascript:'" :target="sp_info.whatsapp ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg"> Whats app </a>
                            </li>
                            <li>
                              <IconTelegram style="margin-right: 13px; font-size: 20px" />
                              <a :href="sp_info.telegram ? 'https://t.me/' + sp_info.telegram : 'javascript:'" :target="sp_info.telegram ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg"> Telegram </a>
                            </li>
                            <li v-if="sp_info.line">
                              <IconLine style="margin-right: 13px; font-size: 20px" />
                              <a :href="sp_info.line" :target="sp_info.line ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg"> Line </a>
                            </li>
                            <li v-else-if="sp_info.line_qrcode" style="position: relative">
                              <div class="kdiso">
                                <div style="display: flex">
                                  <IconLine style="margin-right: 13px; font-size: 20px" />
                                  <a href="javascript:" class="KCBwR lcFyR tuUGG RTmqg"> Line </a>
                                </div>
                                <div class="line-box" style="padding: 10px; border: 1px dashed #ccc">
                                  <img :src="sp_info.line_qrcode" alt="line" style="width: 100%" />
                                </div>
                              </div>
                            </li>
                          </ul> -->
                        </section>
                      </div>
                    </div>
                    <div class="eLxL5" style="inset: 0px auto auto 40px"><div class="oGvoJ" style="top: 8px; transform: rotate(-135deg)"></div></div>
                  </aside>
                </div>
                <!-- <span id="account-links" class="jTZe7 OfW5X"> </span> -->
              </div>

              <!-- 在线客服 -->
              <div class="ADSl">
                <div class="tSWA0 TsMenu" href="javascript:" id="controls-account-links" aria-controls="account-links" style="position: relative">
                  <div>
                    <message-outlined style="margin-right: 10px" />
                    <span class="FeZND">{{ $t('base.server') }}</span>
                  </div>
                  <!-- 下拉框 -->
                  <aside class="GX7lR -SBnes GsMenu" style="top: 100%; left: 0px; padding-top: 16px; z-index: 1; position: absolute">
                    <div class="TlLMR">
                      <div class="ckrPL">
                        <section class="yFHT9">
                          <!-- 24小时客服 -->
                          <h2 v-if="isLogin" class="fOz0y dls-boa17d" style="margin-top: 0">
                            <a class="lcFyR tuUGG RTmqg">{{ $t('_zdy._24zrkf') }}</a>
                          </h2>
                          <ul v-if="isLogin">
                            <li>
                              <CommentOutlined style="margin-right: 13px; font-size: 20px" />
                              <router-link :to="{ name: sp_info.type == 1 ? 'message' : 'storeMessage', query: { customer: 'agent' } }" class="KCBwR lcFyR tuUGG RTmqg"> {{ $t('_n2._jscldd') }} </router-link>
                            </li>
                            <li v-if="sp_info.whatsapp">
                              <IconWhatsApp style="margin-right: 13px; font-size: 20px" />
                              <a :href="sp_info.whatsapp ? 'https://wa.me/' + sp_info.whatsapp : 'javascript:'" :target="sp_info.whatsapp ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg"> Whats app </a>
                            </li>
                            <li v-if="sp_info.telegram">
                              <IconTelegram style="margin-right: 13px; font-size: 20px" />
                              <a :href="sp_info.telegram ? 'https://t.me/' + sp_info.telegram : 'javascript:'" :target="sp_info.telegram ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg"> Telegram </a>
                            </li>
                            <li v-if="sp_info.line">
                              <IconLine style="margin-right: 13px; font-size: 20px" />
                              <a :href="sp_info.line" :target="sp_info.line ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg"> Line </a>
                            </li>
                            <li v-else-if="sp_info.line_qrcode" style="position: relative">
                              <div class="kdiso">
                                <div style="display: flex">
                                  <IconLine style="margin-right: 13px; font-size: 20px" />
                                  <a href="javascript:" class="KCBwR lcFyR tuUGG RTmqg"> Line </a>
                                </div>
                                <div class="line-box" style="padding: 10px; border: 1px dashed #ccc">
                                  <img :src="sp_info.line_qrcode" alt="line" style="width: 100%" />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </section>
                      </div>
                    </div>
                    <div class="eLxL5" style="inset: 0px auto auto 40px"><div class="oGvoJ" style="top: 8px; transform: rotate(-135deg)"></div></div>
                  </aside>
                </div>
                <!-- <span id="account-links" class="jTZe7 OfW5X"> </span> -->
              </div>

              <!-- 语言 -->
              <div class="rejbY ADSl">
                <!-- 语言 -->
                <language ref="language"></language>
              </div>
              <!-- 下载app -->
              <div class="rejbY ADSl">
                <a href="javascript:" class="lHtRS cQ7wR IdMRc RTmqg ng-mob">
                  <CloudDownloadOutlined style="font-size: 25px; color: #a9c658" class="fAVHJ" />
                  App
                  <div class="app-main" style="padding-top: 16px; left: calc(100% - 371px)">
                    <div class="app-box" style="padding: 10px">
                      <div class="app-download">
                        <img src="../../assets/app_android.png" alt="" />
                      </div>
                      <div class="app-download">
                        <img src="../../assets/app_ios.png" alt="" />
                      </div>
                    </div>
                    <div class="eLxL5" style="inset: 0px auto auto 340px"><div class="oGvoJ" style="top: 8px; transform: rotate(-135deg)"></div></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <nav v-if="isCategory" aria-label="Site Navigation" class="UYr2M fWRC1 kRlHr">
            <div class="Kv5KD">
              <ul class="h2head-ul">
                <li v-for="(item, i) in categoryList" :key="i">
                  <div class="CaTY6 Z7O5e tuUGG RTmqg tuUGG RTmqg">{{ item.name }}</div>
                  <NavMenu :category="item.category"></NavMenu>
                </li>
              </ul>
            </div>
          </nav>
          <!-- <nav id="GlobalDesktopNavFlyout" aria-label="sub-navigation" class="i7Hgd" role="navigation"></nav> -->
        </div>
      </div>
    </section>
  </section>
  <!-- 登录注册弹框 -->
  <toast-signin ref="signin" @tokenStatus="tokenStatus"></toast-signin>
</template>

<script>
import { mapState } from 'vuex'
import NavMenu from './NavMenu.vue'
import ToastSignin from '@/components/signin/ToastSignin.vue'
import Language from './Language.vue'
import { MessageOutlined, DashboardOutlined, CloudDownloadOutlined, CommentOutlined, CrownOutlined, FireOutlined, AlertOutlined, ShareAltOutlined, SettingOutlined } from '@ant-design/icons-vue'
export default {
  name: 'HeadTop2',
  components: {
    NavMenu,
    ToastSignin,
    Language,
    DashboardOutlined,
    CloudDownloadOutlined,
    CommentOutlined,
    CrownOutlined,
    FireOutlined,
    AlertOutlined,
    ShareAltOutlined,
    SettingOutlined,
    MessageOutlined
  },
  props: {
    isCategory: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      //是否登录
      isLogin: false,

      //昵称
      sp_info: {
        avatar: '',
        nickname: '',
        type: 1
      },

      //购物车
      cart: 0,

      //动态增加
      categoryList: [],

      //搜索
      searchVal: '',
      searchShop: [],
      searching: false,

      ajaxMsg: null,
      ajaxTime: 5000,

      is_moni: 1,
      isShare: 1 //不允许
    }
  },
  created() {
    // console.log(this.$route.query)
    let that = this
    if (this.$route.query.token) {
      let token = that.$route.query.token
      this.onAutoLogin(token)
    } else {
      this.tokenStatus()
    }

    // this.getTopBanner()

    //获取基本
    this.$api.home.getCartNum().then((res) => {
      that.cart = res.data.cart
    })

    //获取基本信息
    this.$store.dispatch('base/getBase', {})
  },
  computed: {
    ...mapState({
      msgCenter: (state) => state.base.msgCenter
    })
  },
  mounted() {
    //获取分类
    if (this.isCategory) {
      this.getCategoryList()
    }

    // 定时获取消息通知
    // this.runGetBase()
    // document.addEventListener('mousemove', this.onMouseMove)
  },
  beforeUnmount() {
    // this.clearAjax()
    // document.removeEventListener('mousemove', this.onMouseMove)
  },
  methods: {
    /**
     * 异步循环获取消息
     */
    // clearAjax() {
    //   clearInterval(this.ajaxMsg)
    //   this.ajaxTime = 0
    //   console.log(this.ajaxTime, 'this.ajaxTime')
    // },
    // async runGetBase() {
    //   this.$store.dispatch('base/getBase', {})

    //   // console.log(this.ajaxTime, 'this.runGetBase')

    //   if (this.ajaxTime > 0) {
    //     this.ajaxMsg = setTimeout(this.runGetBase, this.ajaxTime)
    //   }
    // },
    onAutoLogin(token) {
      let that = this
      localStorage.setItem(that.$Const.KEY_TOKEN, token)
      that.$api.auth.autoLogin({ token: token }).then((res) => {
        //存入缓存
        let info = res.data
        delete info.token
        localStorage.setItem(that.$Const.KEY_INFO, JSON.stringify(info))
        that.tokenStatus()
        setTimeout(function () {
          that.$router.push({ name: 'index' })
        }, 1000)
      })
    },

    /**
     * 搜索
     */
    onSearchInput() {
      //通知上级
      console.log(this.searchVal)

      if (this.searchVal.length < 2) {
        this.searchShop = []
        return
      }

      // 搜索符合的店铺名称
      this.searching = true

      let that = this
      this.$api.home.searchShop({ search: this.searchVal }).then((res) => {
        that.searching = false
        that.searchShop = res.data
      })
    },
    onSearchShop(val) {
      this.$router.push({ name: 'shopIndex', query: { id: val.id } })
    },
    onClearSearch() {
      this.searchVal = ''
      this.searchShop = []
    },
    onCateSearch() {
      this.onCloseDY()
      this.$router.push({ name: 'category', query: { id: 0, level: 0, keyword: this.searchVal } })
    },
    getTopBanner() {
      //获取轮播图
      let that = this

      //获取活动
      this.$api.home
        .getActivity({
          key: ['home_top']
        })
        .then((res) => {
          that.homeTop = res.data.home_top
        })
    },
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      if (this.isLogin) {
        //获取基本
        let that = this
        this.sp_info = this.$common.getInfo()
        this.$api.home.getService().then((res) => {
          that.$common.setService(res.data)
          that.sp_info = that.$common.getInfo()
          that.is_moni = res.data.is_moni
        })

        //获取分享
        this.$api.home.getShare().then((res) => {
          that.isShare = res.data.share
        })
        // console.log(this.loginInfo,'loginInfo')
      }
    },
    /**
     * 退出登录
     */
    onSignout() {
      localStorage.removeItem(this.$Const.KEY_TOKEN)
      localStorage.removeItem(this.$Const.KEY_INFO)
      this.isLogin = false
      this.sp_info.type = 1 //默认显示用户
      // this.$socket.close()
      // this.$emit('onSignout', [])
    },
    onSignin() {
      this.$refs.signin.onShow()
    },
    /**
     * 购物车
     */
    onGoCart() {
      this.$router.push({ name: 'cart', query: { type: 'cart' } })
    },
    getCategoryList() {
      const that = this
      this.$api.category.getCategoryList({ type: 2 }).then((res) => {
        that.categoryList = res.data
      })
    },
    //点击分类
    onCateClick(item) {
      this.onMoreItemLeave(item)
      this.ondYMouseLeave()
      this.$router.push({ name: 'category', query: { id: item.id, level: item.level } })
    },
    onChildCateClick(data) {
      this.onMoreItemLeave(this.categoryList[data.i])
      this.ondYMouseLeave()
      this.$router.push({ name: 'category', query: { id: data.item.id, level: data.item.level } })
    },
    onMouseMove(e) {
      console.log(e)
      // console.log(this.$refs.MoreItem)
      // //点击空白处
      // if (!this.$refs.MoreItem.contains(e.target) && !this.$refs.MoreItem1.contains(e.target)) {
      //   this.isd0 = false
      // }else{
      //   this.isd0 = true
      // }
    },
    onRenderCustom(swiper, current, total) {
      this.total = total
      this.current = current
    },
    //遮罩
    ondYMouseEnter() {
      this.isdY = true
    },
    ondYMouseLeave() {
      this.isdY = false
    },
    // 分类
    onCategoryEnter(item, i) {
      // console.log('sort', i, this.$refs['category' + i])
      this.isSearch = false
      item.show = true
      this.ondYMouseEnter()
      if (this.$refs['category' + i]) {
        this.dYHeight = this.$refs['category' + i][0].$el.offsetHeight + 56
        if (this.isd0) {
          this.dYHeight += 52
        }
      }

      this.dYHeight = this.dYHeight + 'px'
    },
    onCategoryLeave(item) {
      item.show = false
    },
    //更多分类
    onMoreItemEnter(item, i) {
      this.isd0 = true
      if (item) {
        this.onCategoryEnter(item, i)
      } else {
        this.ondYMouseLeave()
      }
    },
    onMoreItemLeave(item) {
      this.isd0 = false
      if (item) {
        this.onCategoryLeave(item)
      }
    }
  }
}
</script>
<style scoped>
.h2head-ul {
  height: 100%;
}
.h2head-ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.h2head-ul li:hover {
  color: #b80009;
  border-bottom-color: #b80009;
}

.app-download {
  width: 170px;
  height: 170px;
  z-index: 10;
}
.app-download img {
  width: 100%;
  height: 100%;
}

.kdiso {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.kdiso:hover .line-box {
  display: flex;
  opacity: 1;
  visibility: visible;
}
.line-box {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
}

h2 {
  font-weight: 700;
}
</style>
